<template>
  <div class="table">
    <div class="e-table">
      <el-table
        @row-click="getRow"
        :data="tableData"
        :row-class-name="rowClassName"
        ref="tableRef"
        v-loading="loading"
        border
        height="100%"
        width="100%"
        fit
      >
        <el-table-column fixed="left" type="index" label="序号" width="50">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>

        <!-- 用户名和用户简介提示标签 -->
        <el-table-column prop="alb_id" label="专辑id" min-width="80"></el-table-column>

        <el-table-column prop="alb_name" label="专辑名" min-width="150">
          <template slot-scope="scope">
            <a :href="'https://music.163.com/#/album?id=' + tableData[scope.$index].alb_id" v-text="tableData[scope.$index].alb_name" target="_blank"></a>
          </template>
        </el-table-column>
        <el-table-column prop="pub_time" label="发行时间" min-width="100">
          <template slot-scope="scope">
            <span>{{tableData[scope.$index].pub_time.slice(0,10)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="company" label="发行方" min-width="130">
        </el-table-column>

        <el-table-column label="歌手" min-width="180">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="tableData[scope.$index].singers.map(item => item.s_name).join(' | ')" placement="top-start">
              <span class="s_name_dsp">{{tableData[scope.$index].singers.map(item => item.s_name).join(' | ')}}</span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="comp_remarks" label="发行方备注" min-width="100">
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="85">
          <template>
            <el-button size="mini" type="warning" style="margin: 0 1px" @click="drawer = true" plain
              >详情</el-button
            >
          </template>
        </el-table-column>


      </el-table>

      <el-drawer
        title="专辑详情"
        :withHeader="false"
        :visible.sync="drawer"
        size="700px"
        direction="rtl">

        <div class="drawer">
          
          <el-descriptions class="margin-top" title="专辑详情" :column="2" size="small" border>
            <template slot="extra">
              <el-button type="primary" size="small" @click="submitUpdate">提交修改</el-button>
            </template>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                专辑名
              </template>
              {{selRowData.alb_name}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-mobile-phone"></i>
                专辑id
              </template>
              {{selRowData.alb_id}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-location-outline"></i>
                发行方
              </template>
              {{selRowData.company}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-tickets"></i>
                发行时间
              </template>
              {{(selRowData.pub_time+'').slice(0,10)}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-office-building"></i>
                发行方备注
              </template>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入专辑备注"
                v-model="selRowData.comp_remarks">
              </el-input>
            </el-descriptions-item>
          </el-descriptions>

          <p class="singer-title">歌手信息</p>
          <el-table
            :data="selRowData.singers"
            :row-class-name="singerRowClassName"
            height="600"
            border
            style="width: 100%">
            <el-table-column
              prop="sid"
              label="歌手id"
              sortable
              min-width="80">
            </el-table-column>
            <el-table-column
              prop="s_name"
              label="歌手"
              min-width="100">
            </el-table-column>
            <el-table-column
              prop="s_fans"
              label="粉丝数"
              sortable
              min-width="80">
            </el-table-column>
            <el-table-column
              label="备注"
              min-width="250">
              <template slot-scope="scope">
                <el-input
                  type="textarea"
                  :rows="1"
                  placeholder="请输入歌手备注"
                  v-model="selRowData.singers[scope.$index].s_remarks">
                </el-input>
              </template>
            </el-table-column>
          </el-table>

        </div>
      </el-drawer>

    </div>
  </div>
</template>

<script>

export default {
  watch: {
    // tableData是el-table绑定的数据
    tableData: {
      // 解决表格显示错位问题
      handler () {
        this.$nextTick(() => {
          // tableRef是el-table绑定的ref属性值
          this.$refs.tableRef.doLayout() // 对 Table 进行重新布局
        })
      },
      deep: true
    }
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    panelBaseUrl: {
      type: String,
      default: "/api/wyy",
    },
    mode: {
      type: String,
      default: "user",
    },
  },
  data() {
    return {
      total: 1,
      totalPage: 1,
      pageIndex: 1,
      pageSize: 50,

      rowIdx: 0,
      drawer: false,
      selRowData: {},
    }
  },
  methods: {
    // 获取id
    getRow(row) {
      this.selRowData = JSON.parse(JSON.stringify(row))
      this.cmpSelRowData = JSON.parse(JSON.stringify(row))
    },

    rowClassName({row, rowIndex}) {
      //把每一行的索引放进row.id
      row.id = rowIndex+1;
    },
    singerRowClassName({row, rowIndex}) {
      //把每一行的索引放进row.id
      row.id = rowIndex+1;
    },

    getIndex($index) {
      return $index + 1 + this.pageSize * (this.pageIndex - 1);
    },

    // 提交更改
    submitUpdate() {
      let _this = this

      let company = this.selRowData.company
      let alb_id = this.selRowData.alb_id
      let new_remarks = this.selRowData.comp_remarks

      // let singers = this.selRowData.singers.filter(ele => ele > 4)
      let singer_to_be_updated = []
      this.selRowData.singers.forEach((val, idx, arr) => {  // 检测变更过的歌手备注信息
        if(_this.cmpSelRowData.singers[idx].s_remarks != _this.selRowData.singers[idx].s_remarks) {
          singer_to_be_updated.push({
            company: _this.selRowData.singers[idx].company,
            sid: _this.selRowData.singers[idx].sid,
            new_remarks: _this.selRowData.singers[idx].s_remarks,
          })
        }
      });
      
      // console.log(singer_to_be_updated)
      
      _this.drawer = false
      _this.dataNotify('提交数据中...', 'info')

      singer_to_be_updated.forEach((val, idx, arr) => {
        this.$http({
          method: "post",
          url: _this.panelBaseUrl + "/album",
          data: {action: "modify_info", sid: val.sid, new_remarks: val.new_remarks},
        })
          .then(function (response) {
            
          })
          .catch(function (error) {
            _this.dataNotify('数据提交失败', 'error')
            console.log(error);
          });
      });
      
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/album",
        data: {action: "modify_info", company, new_remarks},
      })
      .then(function (response) {
        if(response.data.ret == 200)
          _this.$emit('reloadData');
      })
      .catch(function (error) {
        _this.dataNotify('数据提交失败', 'error')
        console.log(error);
      });
    },

    // 获取数据成功后弹窗
    dataNotify(text, status='success') {
      this.$message({
        message: text,
        type: status,
        duration: 1300,
      });
    },
  },
}
</script>

<style lang="less" scope>
.table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .e-table {
    height: 85vh;
    width: 100%;
    overflow: hidden;

    .s_name_dsp {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    /* 标签颜色 */
    .level4, .ws1, .wx {
      background-color: #f0f9eb;
      border-color: #e1f3d8;
      color: #67c23a;
    }

    .level1, .ws4 {
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f56c6c;
    }

    .level2, .ws3 {
      background-color: #fdf6ec;
      border-color: #faecd8;
      color: #e6a23c;
    }

    .level3, .ws2 {
      background-color: #ecf5ff;
      border-color: #d9ecff;
      color: #409EFF;
    }

    .level0, .ws0, .unknown {
      background-color: #f4f4f5;
      border-color: #e9e9eb;
      color: #909399;
    }
  }

  textarea {
    width: 90%;
    height: 100%;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    padding: 5px;
    margin-right: 10px;
    resize:none;
  }

  textarea:focus {
    outline: 1px solid #409EFF;
    border-top: 1px solid #409EFF;
  }

  select {
    display: block;
    width: 100%;
    height: 40px;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    color: #606266;
    font-size: inherit;
    line-height: 40px;
    padding-left: 10px;

    option {
      display: block;
      height: 34px;
    }
  }

  select:focus {
    border-color: #409EFF;
  }
}

.drawer {
  padding: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .el-descriptions {
    width: 100%;
  }

  .singer-title {
    font-size: 16px;
    font-weight: 700;
    margin: 15px 5px 10px;
  }

  .el-table {
    flex: 1;
  }


}

</style>