<template >
  <div>
    <el-container style="height: 100%">
      <asidenav />
      <!-- <tagsview /> -->
      <!-- <el-aside width="150px" >
        
        <el-menu  
        :collapse="isCollapse"
        router
        :default-active="this.$router.path"
        > -->
      <!-- <el-submenu index="1"> -->
      <!-- <template slot="title">歌单</template> -->
      <!-- <el-menu-item-group>
              <el-button @click="setout" style="margin-left:20px">网易退出</el-button>
              <el-menu-item index="/home">网易云音乐</el-menu-item>
              <el-menu-item index="/qqmusic">QQ音乐</el-menu-item> -->

      <!-- <el-dropdown>
                <i class="el-icon-setting" style="margin-right: 15px"></i> -->
      <!-- <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item ><el-button @click="setout">退出</el-button></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span>网易云音乐</span> -->
      <!-- <el-menu-item index="1-2">酷狗音乐</el-menu-item> -->
      <!-- </el-menu-item-group> -->

      <!-- <tagsview></tagsview> -->
      <!-- </el-submenu> -->

      <!-- </el-menu>
      </el-aside> -->
      <!-- <el-container> -->
      
        <!-- <topList/> -->
        <!-- 内容区 -->


      <el-main>

        <div class="output">
          <el-button type="success" @click="fetchData">导出</el-button>
          <el-button type="primary" @click="freshen"
            ><i class="el-icon-refresh"></i
          ></el-button>
        </div>

        <el-row>
          <el-col :span="3">
            <el-select
              style="width: 100%"
              v-model="value"
              multiple
              placeholder="请选择标签"
              @keyup.enter.native="submitall"
              @focus="getItemlist"
            >
              <!-- <el-option label="全选" value="selectAll"   @click.native='selectAll'></el-option> -->
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.tag"
                :value="item.sort"
              >
              </el-option> </el-select
          ></el-col>

          <el-col :span="3"
            ><el-select
              style="width: 100%"
              v-model="value1"
              ref="loanTypeSelect"
              clearable
              @keydown.enter.native="disableVisible"
              @keyup.enter.native="submitall"
              placeholder="请选择排序方式"
            >
              <el-option
                v-for="item in listOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></el-col>

          <el-col :span="6"
            ><el-date-picker
              style="width: 100%"
              v-model="value2"
              type="daterange"
              @keydown.enter.capture.native="submitall"
              value-format="yyyy-MM-ddTHH:mm:ss.lllZ"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker
          ></el-col>
          <!-- <input ref="queryInput" style="position:absolute; z-index:-99;" type="text" @keyup.enter="submitall"> -->
          <el-col :span="3">
            <el-input
              placeholder="搜索歌单网址"
              v-model="input1"
              @change="submitall"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              placeholder="搜索歌单名称"
              v-model="input2"
              @change="submitall"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              placeholder="搜索作者名称"
              v-model="input3"
              @change="submitall"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-select
              style="width: 100%"
              v-model="value3"
              placeholder="全部"
              ref="loanTypeSelecte"
              @keydown.enter.native="disableVisibler"
              @keyup.enter.native="submitall"
            >
              <el-option
                v-for="item in allOptions"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <div>
          <el-row>
            <el-col :span="3">
              <el-input
                placeholder="请输入起始播放量"
                v-model="input4"
                @change="submitall"
              ></el-input>
            </el-col>
            <el-col :span="3">
              <el-input
                placeholder="请输入终止播放量"
                v-model="input5"
                @change="submitall"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <div class="outer">
          <div class="btn">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitall"
            ></el-button>
            <el-button @click="reset">重置</el-button>
          </div>
        </div>

        <el-table
          @row-click="test"
          :data="tableData"
          v-loading="loading"
          border
          style="width: 100%"
        >
          <el-table-column fixed type="index" label="序号" width="70">
            <template slot-scope="scope">
              <span v-text="getIndex(scope.$index)"> </span>
            </template>
          </el-table-column>

          <el-table-column
            prop="p_name"
            label="歌单"
            width="200"
            style="z-index: 1"
          >
            <template scope="scope">
              <a
                :href="[
                  'https://music.163.com/#/playlist?id=' + scope.row.p_id,
                ]"
                target="_Blank"
                >{{ scope.row.p_name }}</a
              >
            </template>
          </el-table-column>
          <el-table-column prop="p_username" label="歌单主" width="100">
          </el-table-column>
          <el-table-column prop="p_plays" label="总播放" width="110">
          </el-table-column>
          <el-table-column prop="p_stars" label="总收藏" width="100">
          </el-table-column>
          <el-table-column prop="p_shares" label="总分享" width="100">
          </el-table-column>
          <el-table-column prop="p_comments" label="总评论" width="100">
          </el-table-column>
          <el-table-column prop="p_7" label="7日播放" width="100">
          </el-table-column>
          <el-table-column prop="p_30" label="30日播放" width="100">
          </el-table-column>
          <el-table-column prop="p_yesterday" label="日播" width="100">
          </el-table-column>
          <el-table-column prop="p_tremarks" label="达备" width="180">
          </el-table-column
          ><el-table-column prop="remarks" label="歌备" width="180">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="225">
            <template>
              <el-button size="mini" type="success" @click="dialogTableVisibled"
                >分析</el-button
              >
              <el-button
                size="mini"
                type="primary"
                @click="undialogFormVisibled"
                >达备</el-button
              >
              <el-button
                size="mini"
                type="primary"
                @click="gddialogFormVisibled"
                >歌备</el-button
              >
            </template></el-table-column
          ></el-table
        ></el-main
      ></el-container
    >
  </div>
</template>
</el-table-column>
</el-table>

<el-dialog title="分析" :visible.sync="dialogTableVisible" :append-to-body="true" @opened="loadLine">
  <div id="myChart" class="chart" ref="chart1" style="width:100%;height:376px" v-if="dialogTableVisible"></div>
</el-dialog>

<el-dialog title="达人备注" :visible.sync="dialogFormVisible" :append-to-body="true"  >
  <el-form :model="form">
    <el-form-item label="备注" :label-width="formLabelWidth" >
      <el-input
  type="textarea"
  :rows="2"
  placeholder="添加备注"
  v-model="remarks">
</el-input>
    </el-form-item>

  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogFormVisibled();clearremark()">清空备注</el-button>
    <el-button type="primary" @click="dialogFormVisibled();handleUpload()">确 定</el-button>
  </div>
</el-dialog>  

<el-dialog title="歌单备注" :visible.sync="dialogFormVisible1" :append-to-body="true" >
  <el-form :model="form">
    <el-form-item label="备注" :label-width="formLabelWidth">
      <el-input
  type="textarea"
  :rows="2"
  placeholder="添加备注"
  v-model="gremarks">
</el-input>
    </el-form-item>

  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogFormVisibled();clearremark1()">清空备注</el-button>
    <el-button type="primary" @click="dialogFormVisibled();handleUploadgd()">确 定</el-button>
  </div>
</el-dialog>  
<el-pagination 
@size-change="handleSizeChange"
@current-change="handleCurrentChange"
:current-page.sync="pageIndex"
      :page-sizes="[10, 20, 50, 100]" 
      :page-size="pageSize" 
      :total="total"
      :page-count="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
      >
    </el-pagination>
        </el-main>
    </el-container>
  </div>
</template>
<script>
// import cookie from '@/utils/cookie'
// import * as echarts from 'echarts';
import topList from "@/components/header";
import tagsview from "@/components/tagsview";
import asidenav from "@/components/asideNav";
import { mapState } from "vuex";
import echarts from "echarts";
export default {
  components: { asidenav, tagsview, topList },
  computed: {
    //引入vuex中state的变量，可以直接this.xxx调用到
    ...mapState(["isCollapse"]),
  },
  watch: {
    //监听vuex中的变量如果变动了就赋值，从而改变菜单栏缩小展开
    isCollapse() {
      if (this.isCollapse) {
        this.subwidth = "64px";
      } else {
        this.subwidth = "200px";
      }
    },
  },

  data() {
    return {
      subwidth: "200px",
      playList: [],
      starList: [],
      shareList: [],
      commentList: [],
      timeList: [],
      chart: "",
      total: 1,
      totalPage: 1,
      formData: "",
      formData1: "",
      pageIndex: 1,
      pageSize: 50,
      labList: [],
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      // isCollapse: false,
      loading: false,
      options: [],
      listOptions: [
        {
          label: "总播放量正序",
          value: "p_plays",
        },
        {
          label: "总播放量反序",
          value: "-p_plays",
        },
        {
          label: "30日播放量正序",
          value: "p_30",
        },
        {
          label: "30日播放量反序",
          value: "-p_30",
        },
        {
          label: "7日播放量正序",
          value: "p_7",
        },
        {
          label: "7日播放量反序",
          value: "-p_7",
        },
        {
          label: "昨日播放量正序",
          value: "p_yesterday",
        },
        {
          label: "昨日播放量反序",
          value: "-p_yesterday",
        },
        {
          label: "id正序",
          value: "p_id",
        },
        {
          label: "id反序",
          value: "-p_id",
        },
      ],
      allOptions: [
        {
          label: "达备有+歌备有",
          value: [1, 1],
          id: 1,
        },
        {
          label: "达备无+歌备无",
          value: [0, 0],
          id: 2,
        },
        {
          label: "达备有+歌备无",
          value: [1, 0],
          id: 3,
        },
        {
          label: "达备无+歌备有",
          value: [0, 1],
          id: 4,
        },
        {
          label: "达备全+歌备有",
          value: [2, 1],
          id: 5,
        },
        {
          label: "达备有+歌备全",
          value: [1, 2],
          id: 6,
        },
        {
          label: "全有+全无",
          value: [2, 2],
          id: 7,
        },
      ],
      value: "",
      value1: "",
      value2: [],
      value3: [],
      dialogTableVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible1: false,
      tableData: [],
      dialogFormVisible: false,
      form: {
        desc: "",
      },
      formLabelWidth: "120px",
      remarks: "",
      gremarks: "",
    };
  },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      vm.getData();
    });
  },
  methods: {
    // 分析图
    loadLine() {
      this.chart = echarts.init(document.querySelector("#myChart"));
      this.axios({
        method: "post",
        url: "/api/wyy/parse", // 路径
        data: { p_id: window.ids, date: this.value2 },
      })
        .then((res) => {
          this.labList = res.data.data;
          (this.playList = []),
            (this.commentList = []),
            (this.shareList = []),
            (this.starList = []),
            (this.timeList = []);
          this.labList.forEach((item) => {
            this.playList.push(item.l_plays);
            this.starList.push(item.l_stars);
            this.commentList.push(item.l_comments);
            this.shareList.push(item.l_shares);
            this.timeList.push(item.l_time);
          });
          let option = {
            title: {},
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: ["播放量", "收藏量", "分享量", "评论量"],
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: this.timeList, // x轴数据
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "播放量",
                type: "line",
                data: this.playList, // y轴数据2
              },

              {
                name: "收藏量",
                type: "line",
                data: this.starList, // y轴数据3
              },
              {
                name: "分享量",
                type: "line",
                data: this.shareList, // y轴数据5
              },
              {
                name: "评论量",
                type: "line",
                data: this.commentList, // y轴数据5
              },
            ],
          };
          // this.myChartOne = echarts.init(document.getElementById('myChart'))
          // this.chart = echarts.init(document.querySelector('#myChart'))
          this.chart.setOption(option);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dialogTableVisibled() {
      this.dialogTableVisible = true;
    },
    undialogFormVisibled() {
      this.dialogFormVisible = true;
    },
    gddialogFormVisibled() {
      this.dialogFormVisible1 = true;
    },
    dialogFormVisibled() {
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    disableVisible() {
      this.$refs.loanTypeSelect.visible = false;
    },
    disableVisibler() {
      this.$refs.loanTypeSelecte.visible = false;
    },

    getIndex($index) {
      return $index + 1 + this.pageSize * (this.pageIndex - 1);
    },
    // 退出
    setout() {
      this.$router.push("/login");
    },
    // 下载
    fetchData() {
      window.open("http://101.35.25.182:8000/api/wyy/export_file");
    },
    // 刷新
    freshen() {
      window.location.reload();
    },
    // 重置
    reset() {
      (this.input1 = ""),
        (this.input2 = ""),
        (this.input3 = ""),
        (this.input4 = ""),
        (this.input5 = ""),
        (this.value = ""),
        (this.value1 = ""),
        (this.value2 = ""),
        (this.value3 = "");
    },
    // 获取标签
    getItemlist() {
      var _this = this;
      this.axios({
        method: "post",
        url: "/api/wyy/tags",
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          _this.options = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleSizeChange: function (size) {
      this.pageIndex = 1;
      this.pageSize = size;
      this.getData();
      // console.log(this.pagesize) //每页下拉显示数据
    },
    handleCurrentChange: function (currentPage) {
      this.pageIndex = currentPage;
      this.getData();
      // console.log(this.currentPage) //点击第几页
    },
    // 表格数据
    getData: function () {
      var _this = this;
      this.axios({
        method: "post",
        url: "/api/wyy/playlists",
        data: {
          action: "list_playlists",
          page: _this.pageIndex,
          limit: _this.pageSize,
          p_id: this.input1,
          p_name: this.input2,
          p_username: this.input3,
          tags: this.value,
          orderby: this.value1,
          remarks: parseInt(_this.value3[1]),
          p_trmarks: parseInt(_this.value3[0]),
          p_yesterday: this.input4 + "_" + this.input5,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(function (response) {
          _this.tableData = response.data.data;
          _this.total = response.data.total;
          _this.totalPage = response.data.total_pages;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 获取id
    test(row) {
      window.ids = row.p_id;
      this.remarks = row.p_tremarks;
      this.gremarks = row.remarks;
    },
    clearremark() {
      this.form.desc = "";
      this.axios({
        method: "post",
        url: "/api/wyy/playlists", // 路径
        data: {
          action: "modify_playlist",
          pid: window.ids,
          newdata: { t_remarks: this.form.desc },
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearremark1() {
      this.formData1 = "";
      this.axios({
        method: "post",
        url: "/api/wyy/playlists", // 路径
        data: {
          action: "modify_playlist",
          pid: window.ids,
          newdata: { p_remarks: this.formData1 },
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleUpload() {
      this.axios({
        method: "post",
        url: "/api/wyy/playlists", // 路径
        data: {
          action: "modify_playlist",
          pid: window.ids,
          newdata: { t_remarks: this.remarks },
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleUploadgd() {
      this.axios({
        method: "post",
        url: "/api/wyy/playlists", // 路径
        data: {
          action: "modify_playlist",
          pid: window.ids,
          newdata: { p_remarks: this.gremarks },
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitall() {
      var _this = this;
      _this.pageIndex = 1;
      this.axios({
        method: "post",
        url: "/api/wyy/playlists",
        data: {
          action: "list_playlists",
          page: _this.pageIndex,
          limit: _this.pageSize,
          p_id: this.input1,
          p_name: this.input2,
          p_username: this.input3,
          tags: this.value,
          orderby: this.value1,
          remarks: parseInt(_this.value3[1]),
          p_trmarks: parseInt(_this.value3[0]),
          p_yesterday: this.input4 + "_" + this.input5,
          //  "p_yesterday":function(){
          //   if(input4==''&&input5==''){
          //     return '';
          //   }else{
          //    return  this.input4+"_"+this.input5;
          //   }
          // }
        },
      })
        .then((res) => {
          _this.tableData = res.data.data;
          // console.log(_this.tableData)
          _this.total = res.data.total;
          _this.totalPage = res.data.total_pages;
          // this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style >
/* .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
  } */
.outer {
  width: 100%;
  height: 40px;
}

.btn {
  margin: 0 auto;
  text-align: center;
}
</style>