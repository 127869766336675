<template>
  <el-container style="height: 100vh" direction="vertical">
    <!-- 页头 -->
    <el-header class="header">
      <p>> {{ panelTitle }}</p>
      <div class="output">
        <span v-text="bindText.slice(0, -1)"></span>
        <el-button type="primary" @click="freshen">
          <i class="el-icon-refresh"></i>
        </el-button>
        <!-- <el-button type="success" @click="fetchData">导出</el-button> -->
      </div>
    </el-header>

    <el-main>

      <div class="tableHeader">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageIndex"
          :page-sizes="[25, 50, 100, 200]"
          :page-size="pageSize"
          :total="total"
          :page-count="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>

        <div class="right">
          <el-popover
            placement="top"
            width="160"
            v-model="filterVisiable">

            <p style="padding-bottom: 2px; margin-bottom: 4px; border-bottom: 1px solid #eee">搜索项:</p>
            <el-row>
              <span>关键字：</span>
              <el-col :span="12">
                <el-input
                  placeholder="请输入关键字"
                  suffix-icon="el-icon-search"
                  v-model="filterValue.keyword">
                </el-input>
              </el-col>
            </el-row>

            <p style="padding-bottom: 2px; margin-bottom: 4px; border-bottom: 1px solid #eee">过滤项:</p>
            <el-row>
              <span>性价比：</span>
              <el-col :span="12">
                <el-select v-model="filterValue.price_level" clearable placeholder="价格标签">
                  <el-option
                    v-for="(item, idx) in price_levelOptions"
                    :key="idx"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

            <el-row>
              <span>微信状态:</span>
              <el-col :span="12">
                <el-select v-model="filterValue.status" clearable placeholder="微信状态">
                  <el-option
                    v-for="(item, idx) in statusOptions"
                    :key="idx"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <span>备注选项:</span>
              <el-col :span="12">
                <el-select v-model="filterValue.remarks" clearable placeholder="备注选项">
                  <el-option
                    v-for="(item, idx) in remarksOptions"
                    :key="idx"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <span>分配微信:</span>
              <el-col :span="12">
                <el-select v-model="filterValue.assign_wx_id" clearable placeholder="微信名">
                  <el-option
                    v-for="(item, idx) in wechatOps"
                    :key="idx"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

            <p style="padding-bottom: 2px; margin-bottom: 4px; border-bottom: 1px solid #eee">排序项:</p>
            <el-row>
              <span>排序方式:</span>
              <el-col :span="12">
                <el-select v-model="filterValue.order" clearable placeholder="排序方式">
                  <el-option
                    v-for="(item, idx) in orderOptions"
                    :key="idx"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            
            <div style="text-align: right; margin: 10px 0 0">
              <el-button size="mini" type="text" @click="reload(); filterVisiable = false">重置</el-button>
              <el-button type="primary" size="mini" @click="filterVisiable = false; freshen()">确定</el-button>
            </div>
            <el-button slot="reference">过滤器</el-button>
          </el-popover>

          <div v-if="!enableEditMode">
            <el-button @click="enableEdit()" type="warning" style="width: 140px" plain>开始编辑</el-button>
          </div>
          <el-button-group v-else>
            <el-button @click="cancelEdit()" type="danger" plain>撤销</el-button>
            <el-button @click="submitEdit()" type="success" plain>提交</el-button>
          </el-button-group>
        </div>
      </div>
      

      <talentsTable
        :tableData="tableData"
        :editStore="editStore"
        :loading="loading"
        :panelBaseUrl="panelBaseUrl"
        :enableEditMode="enableEditMode"
        :wechatDataList="wechatDataList"
        :wechatOps="wechatOps"
        :mode="mode"
      ></talentsTable>
    </el-main>
  </el-container>
</template>

<script>
import talentsTable from "@/components/PanelComponents/talentsTable.vue";

export default {
  components: {talentsTable},
  data() {
    return {
      panelBaseUrl: "/api/wyy",
      playlistBaseUrl: 'https://music.163.com/#/playlist?id=',
      panelTitle: "达人管理面板",
      bindText: '微信绑定：',
      mode: 'user',

      tableData: [],
      tableDataBackup: [],
      editStore: {},

      filterVisiable: false,
      filterValue: {
        price_level: "",
        status: "",
        assign_wx_id: "",
        remarks: [],
        keyword: '',
        order: '',
      },

      price_levelOptions: [{
          value: 1,
          label: '非常贵',
      }, {
          value: 2,
          label: '贵',
      }, {
          value: 3,
          label: '中等',
      }, {
          value: 4,
          label: '便宜',
      },],
      statusOptions: [{
          value: 1,
          label: '已添加',
      }, {
          value: 2,
          label: '申请中',
      }, {
          value: 3,
          label: '暂时不需要',
      },],
      remarksOptions: [{
          value: [1, 1],
          label: '达备+其他备注',
      }, {
          value: [1, 2],
          label: '有达备',
      }, {
          value: [2, 1],
          label: '有其他备注',
      }, {
          value: [0, 0],
          label: '无备注',
      },],
      orderOptions: [
      //   {
      //     value: 't_userid',
      //     label: '用户id升序',
      // }, {
      //     value: '-t_userid',
      //     label: '用户id降序',
      // }, 
      {
          value: 'p_7_max',
          label: '7日最高播放升序',
      }, {
          value: '-p_7_max',
          label: '7日最高播放降序',
      }, {
          value: 'price_level',
          label: '性价比升序',
      }, {
          value: '-price_level',
          label: '性价比降序',
      }, ],
      
      wechatDataList: [],
      wechatOps: [],

      loading: false,
      enableEditMode: false,

      // 分页参数
      pageSize: 100,
      total: 0,
      totalPage: 0,
      pageIndex: 1,
    }
  },
  mounted() {
    // 通过路由获取请求路径信息和页面标题
    this.reload()
  },
  watch: {
    '$route' (to, from) { // 监听路由是否变化,如果变化就重载组件
      this.reload()
    }
  },
  methods: {
    // 重载页面组件
    reload() {
      this.panelBaseUrl = this.$route.meta.api;
      this.playlistBaseUrl = this.$route.meta.playlistBaseUrl
      this.panelTitle = this.$route.meta.title;
      this.filterValue.price_level = ''
      this.filterValue.status = ''
      this.filterValue.assign_wx_id = ''
      this.filterValue.remarks = []
      this.filterValue.keyword = ''
      this.getWechats()
    },
    freshen() {
      this.getData()
    },

    // 获取微信列表数据
    getWechats() {
      let _this = this;
      _this.loading = true;

      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/userinfo",
        data: {
          action: "list_wechats",
        },
      })
        .then(function (response) {
          _this.mode = response.data.mode
          if(_this.mode === 'admin') {
            _this.bindText = '管理用户.'
          } else {
            _this.bindText = '微信绑定：'
          }
          // 获取微信列表
          _this.wechatDataList = response.data.data
          _this.wechatOps = [{
              value: '',
              label: '无'
            }]
          _this.wechatDataList.forEach((v) => {
            _this.wechatOps.push({
              value: v.wx_id,
              label: v.wx_name
            })
            if(_this.mode === 'user' && v.bind) {
              _this.bindText +=  v.wx_name + '、'
            }
          })
        }).then(function () {
          _this.getData()
        })
        .catch(function (error) {
          console.log(error)
        })
    },

    // 提交更新数据
    submitUpdateData() {
      let _this = this;
      _this.loading = true;

      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/userinfo",
        data: {
          action: "talent_opt",
          data: _this.editStore,
        },
      })
        .then(function (response) {
          if(response.data.ret == 0) {  // 提交更新成功
            _this.dataNotify('修改提交成功')
            _this.getData()
          }
        })
        .catch(function (error) {
          console.log(error)
          _this.loading = false;
        })
    },

    // 获取页面数据
    getData() {
      let _this = this;
      _this.loading = true;

      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/userinfo",
        data: {
          action: "list_userinfo",
          limit: _this.pageSize,
          page: _this.pageIndex,
          filter: _this.filterValue
        },
      })
        .then(function (response) {
          _this.tableData = response.data.data;
          _this.editStore = {}
          _this.tableData.forEach(function (val) {
            _this.editStore[val.t_userid] = {}  // 设置好对象避免v-model引用到undefined的属性

            _this.wechatDataList.forEach((v) => {
              _this.editStore[val.t_userid]['wx-' + v.wx_id] = val['wx-' + v.wx_id]
            })
          })
          // console.log(response.data)
          _this.total = response.data.total;
          _this.totalPage = response.data.total_pages;
          _this.dataNotify('数据更新成功')
          setTimeout(() => {_this.loading = false;}, 800)
        })
        .catch(function (error) {
          dataNotify('数据获取失败', 'error')
          console.log(error);
        });
    },
    // 获取数据成功后弹窗
    dataNotify(text, status='success') {
      this.$message({
        message: text,
        type: status,
        duration: 1300,
      });
    },

    // 加载编辑数据到editStore
    loadEditStore() {
      let _this = this
      _this.tableData.forEach((val, index) => {
        _this.editStore[val.t_userid].remarks = val.remarks
        _this.editStore[val.t_userid].other_remarks = val.other_remarks
        _this.editStore[val.t_userid].assign_wx_id = val.assign_wx_id
        _this.editStore[val.t_userid].price_level = val.price_level

        // _this.editStore[val.t_userid]['wx-' + ]

        _this.wechatDataList.forEach((wx) => {
          _this.editStore[val.t_userid]['wx-' + wx.wx_id] = val['wx-' + wx.wx_id]
        })
      })
    },

    // 解析编辑的数据并更新到tableData
    resolveEditStore() {
      let _this = this
      this.tableData.forEach((val, idx) => {
        _this.tableData[idx].remarks = _this.editStore[val.t_userid].remarks
        _this.tableData[idx].other_remarks = _this.editStore[val.t_userid].other_remarks
        _this.tableData[idx].assign_wx_id = _this.editStore[val.t_userid].assign_wx_id
        _this.tableData[idx].price_level = _this.editStore[val.t_userid].price_level
        _this.wechatDataList.forEach((w) => {
          _this.tableData[idx]['wx-' + w.wx_id] = _this.editStore[val.t_userid]['wx-' + w.wx_id]
        })
      })
    },

    enableEdit: function () {
      this.loading = true;
      let _this = this;
      setTimeout(() => {
        this.loadEditStore()
        _this.enableEditMode = true;
        setTimeout(() => {
          _this.loading = false;
        }, 100);
      }, 50);
    },

    cancelEdit: function () {
      this.loading = true;
      let _this = this;
      setTimeout(() => {
        _this.loadEditStore()
        _this.enableEditMode = false;
        setTimeout(() => {
          _this.dataNotify('已撤销更改')
          _this.loading = false;
        }, 100);
      }, 50);
    },

    submitEdit: function () {
      this.loading = true;
      let _this = this
      // console.log(this.editStore)
      setTimeout(() => {
        _this.resolveEditStore()
        _this.submitUpdateData()
        _this.enableEditMode = false;
        // setTimeout(() => {
        //   _this.loading = false;
        // }, 100);
      }, 50);
    },

    handleSizeChange: function (size) {
      this.pageIndex = 1;
      this.pageSize = size;
      this.getData();
      // console.log(this.pagesize) //每页下拉显示数据
    },
    handleCurrentChange: function (currentPage) {
      this.pageIndex = currentPage;
      this.getData();
      // console.log(this.currentPage) //点击第几页
    },

  }
}
</script>

<style lang="less" scope>
.header {
  background-color: #545c64;
  color: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .output .el-button {
    margin-left: 20px;
  }
}

.el-main {
  display: flex;
  flex-direction: column;

  .right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .el-button {
      margin: 0 2px;
    }
  }

  .tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.el-popper {
  width: auto !important;

  .el-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .el-col {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .el-select {
    margin: 2px 0 2px;
  }
}

</style>