import Login from '@/views/login'
import Home from '@/views/home'
// import QQmusic from '@/views/qqmusic'
import cookie from '@/utils/cookie'
// import control from '@/views/control'
// import wyymusic from '@/views/wyymusic'
// import kugoumusic from '@/views/kugoumusic'

// 重构页面
import layout from '@/views/layout'
import playlistPanel from '@/views/Panel/playlist'
import songlistPanel from '@/views/Panel/songlist'
import talentPanel from '@/views/Panel/talents'
import albumPanel from '@/views/Panel/album'


export default[
    {
        path:'/',
        redirect: '/login',
        beforeEnter: (to, from, next) => {
          if (cookie.getCookie('username')){
            next()
          } else {    
            if (to.path === "/Login") {
              next()
            } else {
              next('/Login')
            }
          }
        }
      },
      {
        path:'/login',
        component: Login
      },
      {
        path:'/home',
        component:Home,
        meta:{requireAuth:true }
      },
      // {
      //   path:'/qqmusic',
      //   component:QQmusic
      // },
      // {
      //   path:'/wyymusic',
      //   component:wyymusic
      // },
      // {
      //   path:'/control',
      //   component:control
      // },
      // {
      //   path:'/kugoumusic',
      //   component:kugoumusic
      // },

      // 重构后路由
      {
        path: '/layout',
        component: layout,

        children: [
          {
            path: 'wyy/playlistPanel',
            component: playlistPanel,
            meta: {
              title: '网易云音乐 > 歌单监控面板',
              api: '/api/wyy',
              playlistBaseUrl: 'https://music.163.com/#/playlist?id='
            }
          },
          {
            path: 'wyy/songPanel',
            component: songlistPanel,
            meta: {
              title: '网易云音乐 > 歌曲监控面板',
              api: '/api/wyy',
              playlistBaseUrl: 'https://music.163.com/#/playlist?id='
            }
          },
          {
            path: 'wyy/talentPanel',
            component: talentPanel,
            meta: {
              title: '网易云音乐 > 达人管理面板',
              api: '/api/wyy',
              playlistBaseUrl: 'https://music.163.com/#/playlist?id='
            }
          },
          {
            path: 'wyy/albumPanel',
            component: albumPanel,
            meta: {
              title: '网易云音乐 > 专辑管理面板',
              api: '/api/wyy',
              playlistBaseUrl: 'https://music.163.com/#/playlist?id='
            }
          },

          {
            path: 'qq/playlistPanel',
            component: playlistPanel,
            meta: {
              title: 'qq音乐 > 歌单监控面板',
              api: '/api/qq',
              playlistBaseUrl: 'https://y.qq.com/n/ryqq/playlist/'
            }
          },
          {
            path: 'qq/songPanel',
            component: songlistPanel,
            meta: {
              title: 'qq音乐 > 歌曲监控面板',
              api: '/api/qq',
              playlistBaseUrl: 'https://y.qq.com/n/ryqq/playlist/'
            }
          },
          {
            path: 'qq/talentPanel',
            component: talentPanel,
            meta: {
              title: 'qq音乐 > 达人管理面板',
              api: '/api/qq',
              playlistBaseUrl: 'https://y.qq.com/n/ryqq/playlist/'
            }
          },

          {
            path: 'kugou/playlistPanel',
            component: playlistPanel,
            meta: {
              title: '酷狗音乐 > 歌单监控面板',
              api: '/api/kugou',
              playlistBaseUrl: 'https://www.kugou.com/yy/special/single/'
            }
          },
          {
            path: 'kugou/songPanel',
            component: songlistPanel,
            meta: {
              title: '酷狗音乐 > 歌曲监控面板',
              api: '/api/kugou',
              playlistBaseUrl: 'https://www.kugou.com/yy/special/single/'
            }
          },
          {
            path: 'kugou/talentPanel',
            component: talentPanel,
            meta: {
              title: '酷狗音乐 > 达人管理面板',
              api: '/api/kugou',
              playlistBaseUrl: 'https://www.kugou.com/yy/special/single/'
            }
          },

          {
            path: 'qishui/playlistPanel',
            component: playlistPanel,
            meta: {
              title: '汽水音乐 > 歌单监控面板',
              api: '/api/qishui',
              playlistBaseUrl: ''
            }
          },
          {
            path: 'qishui/songPanel',
            component: songlistPanel,
            meta: {
              title: '汽水音乐 > 歌曲监控面板',
              api: '/api/qishui',
              playlistBaseUrl: ''
            }
          },
          {
            path: 'qishui/talentPanel',
            component: talentPanel,
            meta: {
              title: '汽水音乐 > 达人管理面板',
              api: '/api/qishui',
              playlistBaseUrl: ''
            }
          },


          {
            path: 'admin/log',
            component: () => import('@/views/Panel/log.vue'),
            meta: {
              title: '管理员 > 日志面板',
              api: '/api',
            }
          },
        ]
      },

]
