<template>
  <div id="app">
    <!-- <el-container style="height: 100%;">
      <el-aside width="150px" >
        
        <el-menu  
        :collapse="isCollapse"
        router
        :default-active="this.$router.path"
        >
          
            <el-menu-item-group>
              <el-button @click="setout" style="margin-left:20px">QQ退出</el-button>
              <el-menu-item index="/home" >网易云音乐</el-menu-item>
              <el-menu-item index="/qqmusic" @click="addTab">QQ音乐</el-menu-item>
               <el-menu-item index="1-2">酷狗音乐</el-menu-item> -->
    <!-- </el-menu-item-group>
        </el-menu>
      </el-aside>
      
      <el-container>
        
        <el-header > 
          <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab">
            <el-tab-pane
              v-for="(item, index) in editableTabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            >
             
            </el-tab-pane>
          </el-tabs>
        </el-header> 
<el-main> -->
    <router-view></router-view>
    <!-- </el-main>
      </el-container>
    </el-container> -->
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
/* 修复elementui表格错误问题 */
.el-table--border th.gutter:last-of-type {
    display: block!important;
    width: 17px!important;
}
</style>
