<template>
  <el-container style="height: 100vh" direction="vertical">
    <!-- 页头 -->
    <el-header class="header">
      <p>> {{panelTitle}}</p>
      <div class="output">
        <el-button type="success" @click="fetchData">导出</el-button>
        <el-button type="primary" @click="freshen">
          <i class="el-icon-refresh"></i>
        </el-button>
      </div>
    </el-header>

    <el-main>
      <!-- 筛选过滤器 -->
      <div class="filter">
        <el-row :gutter="4">
          <el-col :span="3">
            <el-select
              style="width: 100%; height: 100%;"
              v-model="value"
              multiple
              placeholder="风格"
              @keyup.enter.native="submitall"
              @focus="getItemlist"
            >
              <!-- <el-option label="全选" value="selectAll"   @click.native='selectAll'></el-option> -->
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.tag"
                :value="item.sort"
              >
              </el-option> </el-select
          ></el-col>

          <el-col :span="3">
            <el-select
              style="width: 100%"
              v-model="value1"
              ref="loanTypeSelect"
              clearable
              @keydown.enter.native="disableVisible"
              @keyup.enter.native="submitall"
              placeholder="排序方式"
            >
              <el-option
                v-for="item in listOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></el-col>

          <el-col :span="5">
            <el-date-picker
              style="width: 100%"
              v-model="value2"
              type="daterange"
              @keydown.enter.capture.native="submitall"
              value-format="yyyy-MM-ddTHH:mm:ss.lllZ"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-col>
          <!-- <input ref="queryInput" style="position:absolute; z-index:-99;" type="text" @keyup.enter="submitall"> -->

          <el-col :span="2">
            <el-select
              style="width: 100%"
              v-model="value3"
              placeholder="筛选"
              ref="loanTypeSelecte"
              @keydown.enter.native="disableVisibler"
              @keyup.enter.native="submitall"
            >
              <el-option
                v-for="item in allOptions"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="2">
            <el-input
              placeholder="歌单网址"
              v-model="input1"
              @change="submitall"
            ></el-input>
          </el-col>

          <el-col :span="2">
            <el-input
              placeholder="歌单名称"
              v-model="input2"
              @change="submitall"
            ></el-input>
          </el-col>

          <el-col :span="2">
            <el-input
              placeholder="作者名称"
              v-model="input3"
              @change="submitall"
            ></el-input>
          </el-col>

          <el-col :span="2">
            <el-input
              placeholder="起始播放量"
              v-model="input4"
              @change="submitall"
            ></el-input>
          </el-col>

          <el-col :span="2">
            <el-input
              placeholder="终止播放量"
              v-model="input5"
              @change="submitall"
            ></el-input>
          </el-col>
        </el-row>
      </div>

      <div class="tableHeader">
        <!-- 分页器 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageIndex"
          :page-sizes="[25, 50, 100, 200]"
          :page-size="pageSize"
          :total="total"
          :page-count="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
        <div class="remarks">
          达备数量：
          <span v-text="talentRemarksNum"></span>
        </div>

        <!-- 搜索与重置按钮 -->
        <div class="outer">
          <!-- 提示组件 -->
          <el-tooltip style="width: 55px; height: 40px" placement="top">
            <div slot="content">提示<br/>在表格中按住 shift 再滚动鼠标滚轮可以横行滚动表格</div>
            <el-button style="width: 55px; height: 40px"><i class="el-icon-s-opportunity"></i></el-button>
          </el-tooltip>
          <!-- 查询按钮 -->
          <div class="btn">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitall"
            ></el-button>
            <el-button @click="reset">重置</el-button>
          </div>
        </div>
      </div>

      <!-- 向表格组件传递表格数据、加载状态、面板基础url、触发事件 -->
      <playlistTable
        :tableData="tableData"
        :loading="loading"
        :panelBaseUrl="panelBaseUrl"
        :value2="value2"
        :playlistBaseUrl="playlistBaseUrl"
        @reloadData="getData"
      ></playlistTable>
    </el-main>
  </el-container>
</template>

<script>
import playlistTable from "@/components/PanelComponents/playlistTable";

export default {
  components: { playlistTable },
  computed: {},

  data() {
    return {
      panelBaseUrl: "/api/wyy",
      playlistBaseUrl: 'https://music.163.com/#/playlist?id=',
      panelTitle: "歌单监控面板",

      total: 1,
      totalPage: 1,
      formData: "",
      formData1: "",
      pageIndex: 1,
      pageSize: 100,
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      // isCollapse: false,
      loading: false,
      options: [],
      listOptions: [
        {
          label: "总播放量正序",
          value: "p_plays",
        },
        {
          label: "总播放量反序",
          value: "-p_plays",
        },
        {
          label: "30日播放量正序",
          value: "p_30",
        },
        {
          label: "30日播放量反序",
          value: "-p_30",
        },
        {
          label: "7日播放量正序",
          value: "p_7",
        },
        {
          label: "7日播放量反序",
          value: "-p_7",
        },
        // {
        //   label: "日增播放量正序",
        //   value: "p_yesterday",
        // },
        // {
        //   label: "日增播放量反序",
        //   value: "-p_yesterday",
        // },
        {
          label: "日增收藏量正序",
          value: "p_stars_yesterday",
        },
        {
          label: "日增收藏量反序",
          value: "-p_stars_yesterday",
        },
        {
          label: "7日收藏量正序",
          value: "p_stars_7",
        },
        {
          label: "7日收藏量反序",
          value: "-p_stars_7",
        },
        {
          label: "30日收藏量正序",
          value: "p_stars_30",
        },
        {
          label: "30日收藏量反序",
          value: "-p_stars_30",
        },
        {
          label: "id正序",
          value: "p_id",
        },
        {
          label: "id反序",
          value: "-p_id",
        },
      ],
      allOptions: [
        {
          label: "达备有+歌备有",
          value: [1, 1],
          id: 1,
        },
        {
          label: "达备无+歌备无",
          value: [0, 0],
          id: 2,
        },
        {
          label: "达备有+歌备无",
          value: [1, 0],
          id: 3,
        },
        {
          label: "达备无+歌备有",
          value: [0, 1],
          id: 4,
        },
        {
          label: "达备全+歌备有",
          value: [2, 1],
          id: 5,
        },
        {
          label: "达备有+歌备全",
          value: [1, 2],
          id: 6,
        },
        {
          label: "全有+全无",
          value: [2, 2],
          id: 7,
        },
        // 下面是价格标签筛选选项，暂时借用备注选项的接口实现
        {
          label: "价格：便宜",
          value: [-1, 4],
          id: 8,
        },
        {
          label: "价格：中等",
          value: [-1, 3],
          id: 9,
        },
        {
          label: "价格：贵",
          value: [-1, 2],
          id: 10,
        },
        {
          label: "价格：非常贵",
          value: [-1, 1],
          id: 11,
        },
      ],
      wx_opt_start_id: 12,
      value: "",
      value1: "",
      value2: [],
      value3: [],
      dialogTableVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible1: false,
      tableData: [],
      dialogFormVisible: false,
      form: {
        desc: "",
      },
      formLabelWidth: "120px",
      remarks: "",
      gremarks: "",

      talentRemarksNum: 0,
    };
  },
  // beforeRouteEnter: (to, from, next) => {
  //   next()
  // },
  // beforeRouteLeave: (to, from, next) => {
  //   clearInterval(this.monitorItv)
  //   next()
  // },
  mounted() {
    let _this = this
    // 通过路由获取请求路径信息和页面标题
    this.reload()
    if(_this.monitorItv) clearInterval(_this.monitorItv)
    this.monitorItv = setInterval(() => {
      _this.updateTalentRemarksNum()
    }, 30000)
    this.getWechatId()
  },
  watch: {
    '$route' (to, from) { // 监听路由是否变化,如果变化就重载组件
      this.reload()
    }
  },
  methods: {
    // 重载组件
    reload() {
      this.panelBaseUrl = this.$route.meta.api;
      this.playlistBaseUrl = this.$route.meta.playlistBaseUrl
      this.panelTitle = this.$route.meta.title;
      this.getData();
      this.updateTalentRemarksNum()
    },
    // 更新达备数量
    updateTalentRemarksNum () {
      let _this = this
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + '/monitoring',
        data: {"action": "talent_remarks",}
      })
        .then(function (res) {
          // console.log(res.data)
          _this.talentRemarksNum = res.data.talent_remarks_num
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    disableVisible() {
      this.$refs.loanTypeSelect.visible = false;
    },
    disableVisibler() {
      this.$refs.loanTypeSelecte.visible = false;
    },

    // 下载
    fetchData() {
      // window.open(
      //   "http://101.35.25.182:8000" + this.panelBaseUrl + "/export_file"
      // );
      let _this = this
      _this.$message({type: "info", message: "下载文件中...", duration: 1000})

      this.$http({
        method: 'POST',
        url: _this.panelBaseUrl + '/playlists',
        responseType: 'blob', // 重点！一定要设置responseType为blob
        data: {
          action: "export_file", //选择功能(必填)
          data: "" //参数总和（必须存在）
        }
      })
        .then(res => {
          // console.log(res)
           //构造blob对象，type是文件类型，详情可以参阅blob文件类型
          let blob = new Blob([res.data], { type: 'application/zip' }) //我是下载zip压缩包
          let url = window.URL.createObjectURL(blob) //生成下载链接
          const link = document.createElement('a') //创建超链接a用于文件下载
          link.href = url //赋值下载路径
          link.target = '_blank' //打开新窗口下载，不设置则为在本窗口下载
          link.download = `${_this.panelBaseUrl.split('/')[2]}_${_this.formatDateTime()}.xlsx` //下载的文件名称（不设置就会随机生成）
          link.click() //点击超链接触发下载
          URL.revokeObjectURL(url) //释放URL
          this.$message.success('下载完成')
        })
        .catch(error => this.$message.error('下载失败'))
    },

    // 格式化时间成 YYYY-mm-dd
    formatDateTime(obj) {
      let date = new Date(obj);
      if (obj === undefined) {
        date = new Date();
      } else {
        date = new Date(obj)
      }
      let y = 1900 + date.getYear();
      let m = "0" + (date.getMonth() + 1);
      let d = "0" + date.getDate();
      return y + "-" + m.substring(m.length - 2, m.length) + "-" + d.substring(d.length - 2, d.length);
    },

    // 刷新
    freshen() {
      // window.location.reload();
      this.getData();
    },
    // 获取数据成功后弹窗
    dataNotify() {
      // this.$notify({
      //   title: '成功',
      //   message: '数据更新成功',
      //   type: 'success',
      //   duration: 1000,
      // });
      this.$message({
        message: '数据更新成功',
        type: 'success',
        duration: 1300,
      });
    },
    // 重置
    reset() {
      (this.input1 = ""),
        (this.input2 = ""),
        (this.input3 = ""),
        (this.input4 = ""),
        (this.input5 = ""),
        (this.value = ""),
        (this.value1 = ""),
        (this.value2 = []),
        (this.value3 = []);
      this.getData()
    },
    // 获取标签
    getItemlist() {
      var _this = this;
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/tags",
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          _this.options = response.data.data;
        })
        .catch(function (error) {
          // console.log(error);
        });
    },

    // 分页器触发
    handleSizeChange: function (size) {
      this.pageIndex = 1;
      this.pageSize = size;
      this.getData();
      // console.log(this.pagesize) //每页下拉显示数据
    },
    handleCurrentChange: function (currentPage) {
      this.pageIndex = currentPage;
      this.getData();
      // console.log(this.currentPage) //点击第几页
    },

    // 发送请求获取表格数据
    getData: function () {
      var _this = this;
      _this.loading = true;

      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/playlists",
        data: {
          action: "list_playlists",
          page: _this.pageIndex,
          limit: _this.pageSize,
          p_id: this.input1,
          p_name: this.input2,
          p_username: this.input3,
          tags: this.value,
          orderby: this.value1,
          remarks: _this.value3[1],
          p_trmarks: parseInt(_this.value3[0]), // 如果传入-1表示筛选价格标签 -2表示筛选指派微信
          p_yesterday: this.input4 + "_" + this.input5,
        },
        // headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(function (response) {
          _this.tableData = response.data.data;
          // console.log(response.data)
          _this.total = response.data.total;
          _this.totalPage = response.data.total_pages;
          _this.dataNotify()
          setTimeout(() => {_this.loading = false;}, 800)
        })
        .catch(function (error) {
          // console.log(error);
        });
    },

    // 提交更改
    submitall() {
      var _this = this;
      _this.loading = true;
      _this.pageIndex = 1;
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/playlists",
        data: {
          action: "list_playlists",
          page: _this.pageIndex,
          limit: _this.pageSize,
          p_id: this.input1,
          p_name: this.input2,
          p_username: this.input3,
          tags: this.value,
          orderby: this.value1,
          remarks: _this.value3[1],
          p_trmarks: parseInt(_this.value3[0]), // 如果传入-1表示筛选价格标签 -2表示筛选指派微信
          p_yesterday: this.input4 + "_" + this.input5,
        },
      })
        .then((res) => {
          _this.tableData = res.data.data;
          _this.total = res.data.total;
          _this.totalPage = res.data.total_pages;
          _this.dataNotify()
          setTimeout(() => {_this.loading = false;}, 800)
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getWechatId() {
      let _this = this;

      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/userinfo",
        data: {
          action: "list_wechats",
        },
      })
        .then(function (response) {
          // 获取微信列表
          let start_id = _this.wx_opt_start_id
          if(_this.allOptions.length <= start_id) {
            response.data.data.forEach((v) => {
              _this.allOptions.push(
                {
                label: "指派: " + v.wx_name,
                value: [-2, v.wx_id],
                id: start_id,
              })
              start_id += 1
            })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
};
</script>

<style lang="less" scoped>
.outer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}

.btn {
  margin: 0 10px;
  text-align: center;
}

.header {
  background-color: #545c64;
  color: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-main {
  display: flex;
  flex-direction: column;

  .filter {
    .el-row {
      margin-bottom: 10px;
      .el-col {
        margin: 0;
      }
    }
  }

  .tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .remarks {
      width: 380px;
      height: 30px;
      line-height: 30px;
      margin-left: 20px;
      color: #333;
    }
  }
}


</style>