import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 导入全局样式表
import '@/assets/css/global.css'
import store from '@/store/index'

import JsonExcel from 'vue-json-excel'
import axios from 'axios'
import echarts from 'echarts'


Vue.prototype.$echarts = echarts

// console.log(process.env.VUE_APP_BASE_URL)
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL //'http://101.35.25.182:8000'
//让ajax携带cookie
axios.defaults.withCredentials = true
Vue.prototype.$http = axios

import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
Vue.component('downloadExcel', JsonExcel)
// 引入cookie
import cookie from '@/utils/cookie'
Vue.prototype.cookie = cookie;
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
