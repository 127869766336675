<template>
  <div class="loginbg">
    <div class="login">
      <div class="message">
        <span>登录</span>
      </div>
      <div id="triangle"></div>
      <!-- 登录表单区域 -->
      <el-form
        ref="loginFormRef"
        class="login_form"
        :model="loginForm"
        :rules="loginFormRules"
        action="javascript:;"
      >
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="el-icon-user-solid"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <button class="loginBtn" @click="login">登录</button>
      </el-form>
    </div>
  </div>
</template>
<script>
import cookie from "@/utils/cookie";
export default {
  data() {
    let validateInput = (rule, value, callback) => {
      if (!this.checkSpecialKey(value)) {
        callback(new Error("不能含有特殊字符！！"));
      } else {
        callback();
      }
    };
    return {
      // 登录表单的数据绑定对象
      loginForm: {
        username: "",
        password: "",
      },
      // 表单的验证规则
      loginFormRules: {
        // 验证用户名
        username: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
        ],
        // 验证密码
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            validator: validateInput,
            message: "不能输入特殊字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 校验输入框不能有特殊字符
    checkSpecialKey(str) {
      let specialKey =
        "[`~!#$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    // 登录预验证
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post(
          "/api/login",
          this.loginForm
        );
        // console.log(res);
        if (res.ret !== 0 && res.ret !== 1) {
          return this.$message.error("登录失败");
        } else {
          // this.$router.push("/home");
          this.cookie.setCookie(this.loginForm, 7);
          this.$message.success("登录成功");
          // 保存user_type到localstore中
          console.log(res);
          localStorage.setItem("user_type", res.user_type);
          // 跳转到主页
          // this.$router.push("/home"); // 这里多中转了一步，但效果是正确的，后面改成直接跳转到网易云歌单库
          this.$router.push('/layout/wyy/playlistPanel')
        }
      });
    },
  },
};
</script>
<style  lang="less" scoped>
.loginbg {
  height: 100%;
  width: 100%;
  background: url(./images/backgroundimage.png) no-repeat center;
  background-size: 100% 100%;
}
.login {
  width: 420px;
  height: 420px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
}
.message {
  width: 400px;
  height: 60px;
  background-color: #189f92;
  position: relative;
  left: -18px;
  top: 50px;
}
.message span {
  line-height: 60px;
  font-size: 16px;
  margin-left: 58px;
  color: white;
}
#triangle {
  background: url(./images/triangle.png);
  width: 18px;
  height: 10px;
  position: relative;
  top: 50px;
  left: -18px;
}
.login_form {
  position: absolute;
  bottom: 100px;
  width: 100%;
  padding: 0 40px 0 58px;
  box-sizing: border-box;
}
.loginBtn {
  width: 322px;
  height: 50px;
  background-color: #189f92;
  border: none;
  border-radius: 3px;
  font-size: 18px;
  color: #fff;
}
</style>