import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index.js'
Vue.use(Vuex)
export default new Vuex.Store({
  state: { 
    //tags数组
    tags:[],
    //tagsview标签显示隐藏
    isCollapse:false,
  },
  mutations: {
    pushtags(state,val){
      //如果等于-1说明tabs不存在那么插入，否则什么都不做
      //findindex找角标，循环判断一下，如果等于那么就代表有相同的，就不必添加，如果找不到那就是-1.就添加
      let result = state.tags.findIndex(item => item.name === val.name)
      result === -1 ? state.tags.push(val) : ''
      // state.tags.push(val)
      // result.push(val)
      
      // console.log(val)
      console.log(state.tags)
    },
    //关闭标签
    closeTab(state, val) {
      //同上，找角标，然后用角标的位置对应删除一位。splice：这是数组的删除方法
      let result = state.tags.findIndex(item => item.name === val.name)
      state.tags.splice(result, 1)
  },
  //关闭所有tagsview标签
    cleartagsview(state,val){
      //清空数组
      state.tags=[]
      //跳转到首页,val接受传过来的当前路由
      if(val !== "/home"){
        router.push({path:"/home"})
      }
    },
    //改变tagsview显示隐藏
    changeisshow(state){
      state.isCollapse=!state.isCollapse
    },
  },
  actions: {
  },
  modules: {
  }
})


// Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {
//     openTab:[],//所有打开的路由
//     activeIndex:'/home' , //也可以是默认激活路径；激活状态
//   },
//   mutations: {
//     // 添加tabs
//     add_tabs (state, data) {
//       this.state.openTab.push(data);
//     },
//     // 删除tabs
//     delete_tabs (state, route) {
//       let index = 0;
//       for (let option of state.openTab) {
//         if (option.route === route) {
//           break;
//         }
//         index++;
//       }
//       this.state.openTab.splice(index, 1);
//     },
//     // 设置当前激活的tab
//     set_active_index (state, index) {
//       this.state.activeIndex = index;
//     },
//   },

//   actions: {
//   },
//   modules: {
//   }
// })
