<template>
  <div class="table">
    <div class="e-table">
      <el-table
        @row-click="getRow"
        :data="tableData"
        :row-class-name="rowClassName"
        ref="tableRef"
        v-loading="loading"
        border
        height="100%"
        width="100%"
        fit
      >
        <el-table-column fixed="left" type="index" label="序号" width="50">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>

        <!-- 用户名和用户简介提示标签 -->
        <el-table-column fixed="left" prop="t_userid" label="用户id" min-width="100">
        </el-table-column>
        <el-table-column fixed="left" prop="t_username" label="用户名" min-width="180">
          <template slot-scope="scope">
            <el-tooltip placement="bottom" effect="light">
              <div slot="content">{{tableData[scope.$index].t_desc || '暂无自述'}}</div>
              <span v-text="tableData[scope.$index].t_username"></span>
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- 用户备注操作 -->
        <el-table-column fixed="left" prop="remarks" label="用户备注" min-width="180">
          <template slot-scope="scope">
            <div class="" v-show="enableEditMode">
              <!-- 原生方案 -->
              <textarea v-model="editStore[tableData[scope.$index].t_userid].remarks" rows="3"
              ></textarea>

              <!-- elementui方案 已废弃 -->
              <!-- <el-input
                type="textarea"
                :rows="1"
                :autosize="{ minRows: 2, maxRows: 6}"
                resize="none"
                placeholder="请输入用户备注"
                v-model="editStore[tableData[scope.$index].t_userid].remarks">
              </el-input> -->
            </div>
            <div class="" v-show="!enableEditMode">
              {{tableData[scope.$index].remarks}}
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="t_fans" label="粉丝数" min-width="100">
        </el-table-column> -->

        <el-table-column prop="p_7_max" label="7日最高播放" min-width="100">
        </el-table-column>

        <!-- 微信状态操作 -->
        <el-table-column v-for="item in wechatDataList" :key="item.wx_id" :prop="item.wx_id" :label="item.wx_name" min-width="120">
          <template slot-scope="scope">
            <div class="" v-show="enableEditMode">
              <!-- 原生方案 -->
              <select v-model="editStore[tableData[scope.$index].t_userid]['wx-' + item.wx_id]" :style="item.bind? '': 'pointer-events: none;'">
                <option
                  v-for="op in wechatStatus"
                  :key="op.value"
                  :value="op.value"
                >{{op.label}}</option>
              </select>

              <!-- element-ui方案：有bug，选择后不回显 -->
              <!-- <el-select v-model.lazy="tableData[scope.$index][item.id].status" @change="selectorChange" placeholder="请选择" clearable>
                <el-option
                  v-for="witem in wechatStatus"
                  :key="witem.value"
                  :label="witem.label"
                  :value="witem.value">
                </el-option>
              </el-select> -->
            </div>
            <div class="" v-show="!enableEditMode">
              <!-- <el-tag
                type="info"
                :class="tableData[scope.$index][item.id] && tableData[scope.$index][item.id].status || 'unknown'"
              >{{tableData[scope.$index][item.id] && getTagClass(tableData[scope.$index][item.id].status) || '无'}}</el-tag> -->
              <el-tag
                type="info"
                :class="'ws' + tableData[scope.$index]['wx-' + item.wx_id]"
              >{{getAssignTag(tableData[scope.$index]['wx-' + item.wx_id])}}</el-tag>
            </div>
          </template>
        </el-table-column>

        <!-- 价格标签操作 -->
        <el-table-column prop="price_level" label="性价比" min-width="110">
          <template slot-scope="scope">
            <div class="" v-show="enableEditMode">
              <!-- 原生方案 -->
              <select v-model="editStore[tableData[scope.$index].t_userid].price_level">
                <option 
                  v-for="op in priceOps"
                  :key="op.value"
                  :value="op.value"
                >{{op.label}}</option>
              </select>

              <!-- elementui方案 已废弃 -->
              <!-- <el-select v-model.lazy="tableData[scope.$index].price_level" placeholder="未选择" clearable>
                <el-option
                  v-for="item in priceOps"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
            </div>
            <div class="" v-show="!enableEditMode">
              <el-tag
                type="info"
                :class="'level' + (tableData[scope.$index].price_level || 0)"
              >{{getPriceLevel(tableData[scope.$index].price_level)}}</el-tag>
            </div>
          </template>
        </el-table-column>

        <!-- 其他备注操作 -->
        <el-table-column prop="" label="其他备注" min-width="180">
          <template slot-scope="scope">
            <div class="" v-show="enableEditMode">
              <!-- 原生方案 -->
              <textarea v-model="editStore[tableData[scope.$index].t_userid].other_remarks" rows="3"
              ></textarea>

              <!-- elementui方案 已废弃 -->
              <!-- <el-input
                type="textarea"
                :rows="1"
                :autosize="{ minRows: 2, maxRows: 6}"
                resize="none"
                placeholder="请输入用户备注"
                v-model="editStore[tableData[scope.$index].t_userid].other_remarks">
              </el-input> -->
            </div>
            <div class="" v-show="!enableEditMode">
              {{tableData[scope.$index].other_remarks}}
            </div>
          </template>
        </el-table-column>

        <!-- 指派操作 -->
        <el-table-column fixed="right" prop="" label="任务分配" min-width="100">
          <template slot-scope="scope">
            <div class="" v-show="enableEditMode">
              <!-- 原生方案 -->
              <select v-model="editStore[tableData[scope.$index].t_userid].assign_wx_id" :style="mode === 'user'? 'pointer-events: none;': ''">
                <option 
                  v-for="op in wechatOps"
                  :key="op.value"
                  :value="op.value"
                >{{op.label}}</option>
              </select>

              <!-- elementui方案 -->
              <!-- <el-select v-model.lazy="tableData[scope.$index].wechat" placeholder="未指派" clearable>
                <el-option
                  v-for="item in wechatOps"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
            </div>
            <div class="" v-show="!enableEditMode">
              <el-tag
                type="info" 
                :class="tableData[scope.$index].assign_wx_id? 'wx' : 'unknown'"
              >{{getWechatName(tableData[scope.$index].assign_wx_id)}}
              </el-tag>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>

export default {
  watch: {
    // tableData是el-table绑定的数据
    tableData: {
      // 解决表格显示错位问题
      handler () {
        this.$nextTick(() => {
          // tableRef是el-table绑定的ref属性值
          this.$refs.tableRef.doLayout() // 对 Table 进行重新布局
        })
      },
      deep: true
    }
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    panelBaseUrl: {
      type: String,
      default: "/api/wyy",
    },
    mode: {
      type: String,
      default: "user",
    },
    enableEditMode: {
      type: Boolean,
      default: false,
    },
    wechatDataList: {
      type: Array,
      default: [],
    },
    wechatOps: {
      type: Array,
      default: [],
    },
    editStore: {
      type: Object,
      default: {}    
    },
  },
  data() {
    return {
      total: 1,
      totalPage: 1,
      pageIndex: 1,
      pageSize: 50,

      rowIdx: 0,

      wechatStatus: [{
        value: 1,
        label: '已添加'
      }, {
        value: 2,
        label: '申请中'
      },{
        value: 3,
        label: '暂时不需要'
      },{
        value: 4,
        label: '无法添加'
      },{
        value: 0,
        label: '无'
      },],

      priceOps: [{
        value: 1,
        label: '非常贵'
      }, {
        value: 2,
        label: '贵'
      }, {
        value: 3,
        label: '中等'
      }, {
        value: 4,
        label: '便宜'
      },],

      priceTagList: [
        {
          tagName: '未知',
          className: 'sty-5'
        },
        {
          tagName: '超级贵',
          className: 'sty-2'
        },
        {
          tagName: '贵',
          className: 'sty-3'
        },
        {
          tagName: '中等',
          className: 'sty-4'
        },
        {
          tagName: '便宜',
          className: 'sty-1'
        },
      ],

      


    }
  },
  methods: {
    rowClassName({row, rowIndex}) {
      //把每一行的索引放进row.id
      row.id = rowIndex+1;
    },
    getRow(row, col) {
      // if(this.enableEditMode) {
      //   console.log(row, col);

      //   console.log(this.editStore)
      // }
    },
    getIndex($index) {
      return $index + 1 + this.pageSize * (this.pageIndex - 1);
    },
    // 获取价格标签
    getPriceLevel(tag) {
      // console.log()
      switch (tag) {
        case 0: return '无'
        case 1: return '非常贵'
        case 2: return '贵'
        case 3: return '中等'
        case 4: return '便宜'
        default: return '无'
      }
    },
    // 获取微信状态标签
    getAssignTag(tag) {
      switch (tag) {
        case 0: return '无'
        case 1: return '已添加'
        case 2: return '申请中'
        case 3: return '暂时不需要'
        case 4: return '无法添加'
        default: return '无'
      }
    },
    // 获取指派标签
    getWechatName(wid) {
      let res = '无'
      this.wechatDataList.forEach((v) => {
        if(v.wx_id == wid) {
          res = v.wx_name
        }
      })
      return res
    },
    getTagClass(tag) {
      switch (tag) {
        case 'very-expensive': return '非常贵'
        case 'expensive': return '贵'
        case 'normal': return '中等'
        case 'cheap': return '便宜'

        case 'wx1': return '微信1'
        case 'wx2': return '微信2'
        case 'wx3': return '微信3'

        case 'added': return '已添加'
        case 'adding': return '申请中'
        case 'noneed': return '暂时不需要'

        case 'none': return '无'
        default: return '未知'
      }
    },

  },
}
</script>

<style lang="less" scope>
.table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .e-table {
    height: 85vh;
    width: 100%;
    overflow: hidden;

    /* 标签颜色 */
    .level4, .ws1, .wx {
      background-color: #f0f9eb;
      border-color: #e1f3d8;
      color: #67c23a;
    }

    .level1, .ws4 {
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f56c6c;
    }

    .level2, .ws3 {
      background-color: #fdf6ec;
      border-color: #faecd8;
      color: #e6a23c;
    }

    .level3, .ws2 {
      background-color: #ecf5ff;
      border-color: #d9ecff;
      color: #409EFF;
    }

    .level0, .ws0, .unknown {
      background-color: #f4f4f5;
      border-color: #e9e9eb;
      color: #909399;
    }
  }

  textarea {
    width: 90%;
    height: 100%;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    padding: 5px;
    margin-right: 10px;
    resize:none;
  }

  textarea:focus {
    outline: 1px solid #409EFF;
    border-top: 1px solid #409EFF;
  }

  select {
    display: block;
    width: 100%;
    height: 40px;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    color: #606266;
    font-size: inherit;
    line-height: 40px;
    padding-left: 10px;

    option {
      display: block;
      height: 34px;
    }
  }

  select:focus {
    border-color: #409EFF;
  }
}



</style>