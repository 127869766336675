<template>
  <div class="layout">
    <!-- 侧边栏 -->
    <asidenav />

    <router-view class="panel"></router-view>
  </div>
</template>

<script>
import asidenav from "@/components/asideNav";

export default {
  components: { asidenav,},
  name: 'layout',
  data() {
    return {

    }
  }
}
</script>

<style lang="less">

.layout {
  display: flex;
  flex-direction: row;

  asidenav {
    width: 20vw;
  }

  .panel {
    flex: 1;
    width: 80vw;
    height: 100%;
  }
}

</style>