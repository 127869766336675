<template >
  <div class="main" style="height: auto">
    <!-- <el-container direction="vertical">
        <el-aside width="150px" > -->

    <!-- <el-menu router :default-active="this.$router.path">
      <el-menu-item-group>
        旧路由页面导航
        <el-button @click="setout" style="margin-left: 20px">退出</el-button>
        <el-menu-item index="/home" @click="menu('网易云音乐')">网易云音乐</el-menu-item>
        <el-menu-item index="/control" @click="menu('网易云歌曲监控')">网易云歌曲监控</el-menu-item>
        <el-menu-item index="/qqmusic" @click="menu('QQ音乐')">QQ音乐</el-menu-item>
        <el-menu-item index="/kugoumusic" @clickMenu(menu)>酷狗音乐</el-menu-item>

        新路由页面导航
        <router-link to="/layout/wyy/playlistPanel">
          <el-menu-item>网易云歌单监控</el-menu-item>
        </router-link>
        <router-link to="/layout/wyy/songPanel">
          <el-menu-item>网易云歌曲监控</el-menu-item>
        </router-link>
      </el-menu-item-group>
    </el-menu> -->

    <el-menu
      default-active="1-1"
      class="el-menu-vertical-demo"
      style="height: 100%; width: 140px; overflow: hidden"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-menu-item index="0" @click="setout" style="background-color: #dedede; height: 60px">
        <i class="el-icon-close"></i>
        退出系统
      </el-menu-item>

      <el-submenu index="1">
        <template slot="title">
          <!-- <i class="el-icon-s-platform"></i> -->
          <span>网易云音乐</span>
        </template>
        <router-link to="/layout/wyy/playlistPanel">
          <el-menu-item index="1-1">
            <!-- <i class="el-icon-film"></i> -->
            歌单库
          </el-menu-item>
        </router-link>
        <router-link to="/layout/wyy/songPanel">
          <el-menu-item index="1-2">
            <!-- <i class="el-icon-service"></i> -->
            歌曲监控
          </el-menu-item>
        </router-link>
        <router-link to="/layout/wyy/talentPanel">
          <el-menu-item index="1-3">
            <!-- <i class="el-icon-service"></i> -->
            达人管理
          </el-menu-item>
        </router-link>
        <router-link to="/layout/wyy/albumPanel">
          <el-menu-item index="1-4">
            <!-- <i class="el-icon-service"></i> -->
            专辑管理
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="2">
        <template slot="title">
          <!-- <i class="el-icon-s-platform"></i> -->
          <span>QQ音乐</span>
        </template>
        <router-link to="/layout/qq/playlistPanel">
          <el-menu-item index="2-1">
            <!-- <i class="el-icon-film"></i> -->
            歌单库
          </el-menu-item>
        </router-link>
        <!-- <router-link to="/layout/qq/songPanel">
          <el-menu-item index="2-2" disabled>
            歌曲监控
          </el-menu-item>
        </router-link> -->
        <router-link to="/layout/qq/talentPanel">
          <el-menu-item index="2-3">
            <!-- <i class="el-icon-service"></i> -->
            达人管理
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="3">
        <template slot="title">
          <!-- <i class="el-icon-s-platform"></i> -->
          <span>酷狗音乐</span>
        </template>
        <router-link to="/layout/kugou/playlistPanel">
          <el-menu-item index="3-1">
            <!-- <i class="el-icon-film"></i> -->
            歌单库
          </el-menu-item>
        </router-link>
        <!-- <router-link to="/layout/kugou/songPanel">
          <el-menu-item index="3-2" disabled>
            歌曲监控
          </el-menu-item>
        </router-link> -->
        <router-link to="/layout/kugou/talentPanel">
          <el-menu-item index="3-3">
            <!-- <i class="el-icon-service"></i> -->
            达人管理
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="4">
        <template slot="title">
          <!-- <i class="el-icon-s-platform"></i> -->
          <span>汽水音乐</span>
        </template>
        <router-link to="/layout/qishui/playlistPanel">
          <el-menu-item index="4-1">
            <!-- <i class="el-icon-film"></i> -->
            歌单库
          </el-menu-item>
        </router-link>
        <!-- <router-link to="/layout/qishui/songPanel">
          <el-menu-item index="4-2" disabled>
            歌曲监控
          </el-menu-item>
        </router-link> -->
        <router-link to="/layout/qishui/talentPanel">
          <el-menu-item index="4-3">
            <!-- <i class="el-icon-service"></i> -->
            达人管理
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="5" v-if="show_admin_panel">
        <template slot="title">
          <!-- <i class="el-icon-s-platform"></i> -->
          <span>系统管理</span>
        </template>
        <router-link to="/layout/admin/log">
          <el-menu-item index="5-1">
            <!-- <i class="el-icon-film"></i> -->
            登录日志
          </el-menu-item>
        </router-link>
      </el-submenu>

    </el-menu>
  </div>
</template>
<script>
import cookie from "@/utils/cookie";
export default {
  name: "asidenav",
  data() {
    return {
      isCollapse: true,
      show_admin_panel: false,
    };
  },

  mounted() {
    this.$router.push('/layout/wyy/playlistPanel')
    if (localStorage.getItem("user_type") === "mgr") {
      this.show_admin_panel = true;
    }
  },

  methods: {
    // async setout() {
    //   // ! 请求错误，需修改
    //   const { data: res } = await this.$http.post("/api/logout");
    //   console.log(res);
    //   console.log(cookie.getCookie("username"));
    //   cookie.clearCookie("username");
    //   this.$router.push("/login");
    // },
    async setout() {
      // ! 后端登出接口报500错误，目前替代方案
      cookie.clearCookie("username");
      this.$router.push("/login");
    },
    menu(val) {
      this.$store.commit("pushtags", val);
      // console.log(val)
    },

    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    }
  },
};
</script>
<style >
</style>