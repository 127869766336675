<template>
  <el-container style="height: 100vh" direction="vertical">
    <!-- 页头 -->
    <el-header class="header">
      <p>> {{ panelTitle }}</p>
      <div class="output">
        <el-button type="success" @click="fetchData">导出</el-button>
        <!-- <span v-text="bindText.slice(0, -1)"></span> -->
        <el-button type="primary" @click="freshen">
          <i class="el-icon-refresh"></i>
        </el-button>
        <!-- <el-button type="success" @click="fetchData">导出</el-button> -->
      </div>
    </el-header>

    <el-main>

      <div class="tableHeader">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageIndex"
          :page-sizes="[25, 50, 100, 200]"
          :page-size="pageSize"
          :total="total"
          :page-count="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>

        <div class="right">
          <el-popover
            placement="top"
            v-model="filterVisiable">

            <p style="padding-bottom: 2px; margin-bottom: 4px; border-bottom: 1px solid #eee">搜索项:</p>
            <div class="row">
              <span>关键字：</span>
              <div class="col" :span="12">
                <el-input
                  placeholder="请输入关键字"
                  suffix-icon="el-icon-search"
                  v-model="filterValue.keyword">
                </el-input>
              </div>
            </div>

            <p style="padding-bottom: 2px; margin-bottom: 4px; border-bottom: 1px solid #eee">过滤项:</p>
            <div class="row">
              <span>日期范围：</span>
              <div class="col" :span="12">
                <div class="block">
                  <el-date-picker
                    v-model="filterValue.dateValue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </div>
            </div>

            <!-- <p style="padding-bottom: 2px; margin-bottom: 4px; border-bottom: 1px solid #eee">排序项:</p>
            <el-row>
              <span>排序方式:</span>
              <el-col :span="12">
                <el-select v-model="filterValue.order" clearable placeholder="排序方式">
                  <el-option
                    v-for="(item, idx) in orderOptions"
                    :key="idx"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row> -->
            
            <div style="text-align: right; margin: 10px 0 0">
              <el-button size="mini" type="text" @click="reload(); filterVisiable = false">重置</el-button>
              <el-button type="primary" size="mini" @click="filterVisiable = false; freshen()">确定</el-button>
            </div>
            <el-button slot="reference">过滤器</el-button>
          </el-popover>

          <!-- <div v-if="!enableEditMode">
            <el-button @click="enableEdit()" type="warning" style="width: 140px" plain>开始编辑</el-button>
          </div>
          <el-button-group v-else>
            <el-button @click="cancelEdit()" type="danger" plain>撤销</el-button>
            <el-button @click="submitEdit()" type="success" plain>提交</el-button>
          </el-button-group> -->
        </div>
      </div>
      

      <albumTable
        :tableData="tableData"
        :loading="loading"
        :panelBaseUrl="panelBaseUrl"
        :mode="mode"
        @reloadData="getData"
      ></albumTable>
    </el-main>
  </el-container>
</template>

<script>
import albumTable from "@/components/PanelComponents/albumTable.vue";

export default {
  components: {albumTable},
  data() {
    return {
      panelBaseUrl: "/api/wyy",
      playlistBaseUrl: 'https://music.163.com/#/playlist?id=',
      panelTitle: "专辑管理面板",
      bindText: '微信绑定：',
      mode: 'user',

      tableData: [],
      tableDataBackup: [],
      editStore: {},

      filterVisiable: false,
      filterValue: {
        keyword: '',
        dateValue: [],
      },

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

      loading: false,

      // 分页参数
      pageSize: 100,
      total: 0,
      totalPage: 0,
      pageIndex: 1,
    }
  },
  mounted() {
    // 通过路由获取请求路径信息和页面标题
    this.reload()
  },
  watch: {
    '$route' (to, from) { // 监听路由是否变化,如果变化就重载组件
      this.reload()
    }
  },
  methods: {
    // 重载页面组件
    reload() {
      this.panelBaseUrl = this.$route.meta.api;
      this.playlistBaseUrl = this.$route.meta.playlistBaseUrl
      this.panelTitle = this.$route.meta.title;
      this.filterValue.price_level = ''
      this.filterValue.status = ''
      this.filterValue.assign_wx_id = ''
      this.filterValue.remarks = []
      this.filterValue.keyword = ''
      this.getData()
    },
    freshen() {
      this.getData()
    },

    // 获取页面数据
    getData() {
      let _this = this;
      _this.loading = true;

      let pub_time = null
      let keyword = this.filterValue.keyword

      if(_this.filterValue.dateValue.length == 2)
        pub_time = _this.formatDateTime(this.filterValue.dateValue[0]) + '_' + _this.formatDateTime(this.filterValue.dateValue[1])
      else
        pub_time = ''

      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/album",
        data: {
          action: "list_albums",
          limit: _this.pageSize,
          page: _this.pageIndex,
          pub_time: pub_time,
          keyword: keyword
        },
      })
        .then(function (response) {
          _this.tableData = response.data.data;
          _this.total = response.data.total;
          _this.totalPage = response.data.page;
          _this.dataNotify('数据更新成功')
          setTimeout(() => {_this.loading = false;}, 800)
        })
        .catch(function (error) {
          dataNotify('数据获取失败', 'error')
          console.log(error);
        });
    },

    // 下载
    fetchData() {
      let _this = this
      _this.$message({type: "info", message: "下载文件中...", duration: 1000})

      this.$http({
        method: 'POST',
        url: _this.panelBaseUrl + '/album',
        responseType: 'blob', // 重点！一定要设置responseType为blob
        data: {
          action: "export_file", //选择功能(必填)
          data: "" //参数总和（必须存在）
        }
      })
        .then(res => {
          // console.log(res)
           //构造blob对象，type是文件类型，详情可以参阅blob文件类型
          let blob = new Blob([res.data], { type: 'application/zip' }) //我是下载zip压缩包
          let url = window.URL.createObjectURL(blob) //生成下载链接
          const link = document.createElement('a') //创建超链接a用于文件下载
          link.href = url //赋值下载路径
          link.target = '_blank' //打开新窗口下载，不设置则为在本窗口下载
          link.download = `wyy_album_${_this.formatDateTime()}.xlsx` //下载的文件名称（不设置就会随机生成）
          link.click() //点击超链接触发下载
          URL.revokeObjectURL(url) //释放URL
          this.$message.success('下载完成')
        })
        .catch(error => this.$message.error('下载失败'))
    },

    // 格式化时间成 YYYY-mm-dd
    formatDateTime(obj) {
      let date = new Date(obj);
      if (obj === undefined) {
        date = new Date();
      } else {
        date = new Date(obj)
      }
      let y = 1900 + date.getYear();
      let m = "0" + (date.getMonth() + 1);
      let d = "0" + date.getDate();
      return y + "-" + m.substring(m.length - 2, m.length) + "-" + d.substring(d.length - 2, d.length);
    },

    // 获取数据成功后弹窗
    dataNotify(text, status='success') {
      this.$message({
        message: text,
        type: status,
        duration: 1300,
      });
    },

    // 分页器触发
    handleSizeChange: function (size) {
      this.pageIndex = 1;
      this.pageSize = size;
      this.getData();
      // console.log(this.pagesize) //每页下拉显示数据
    },
    handleCurrentChange: function (currentPage) {
      this.pageIndex = currentPage;
      this.getData();
      // console.log(this.currentPage) //点击第几页
    },

  }
}
</script>

<style lang="less" scope>
.header {
  background-color: #545c64;
  color: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .output .el-button {
    margin-left: 20px;
  }
}

.el-main {
  display: flex;
  flex-direction: column;

  .right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .el-button {
      margin: 0 2px;
    }
  }

  .tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.el-popover {
  width: 550px;
  padding: 0 10px;
  margin-right: 10px;
}

.el-picker-panel {
  margin-left: -40% !important;
}

.el-popper {

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    width: 100%;

    .col {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .el-input__inner {
        width: 350px;
      }
    }
  }

  .el-select {
    margin: 2px 0 2px;
  }
}

.el-picker-panel__body-wrapper {
  width: 630px;
}

</style>