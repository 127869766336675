<template>
  <el-container style="height: 100vh" direction="vertical">
    <!-- 页头 -->
    <el-header class="header">
      <p>> {{ panelTitle }}</p>
      <div class="output">
        <el-button type="primary" @click="freshen">
          <i class="el-icon-refresh"></i>
        </el-button>
        <!-- <el-button type="success" @click="fetchData">导出</el-button> -->
      </div>
    </el-header>

    <el-main>
      <!-- 搜索筛选栏 -->
      <el-row style="margin: 5px 5px 20px" :gutter="5">
        <el-col :span="4">
          <el-input
            placeholder="搜索歌曲id"
            v-model="input1"
            @change="getData"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="搜索歌曲名称"
            v-model="input2"
            @change="getData"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="搜索作者名称"
            v-model="input3"
            @change="getData"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="搜索作者id"
            v-model="input4"
            @change="getData"
          ></el-input>
        </el-col>

        <el-button
          type="primary"
          icon="el-icon-search"
          @click="getData"
          style="margin-left: 110px"
        ></el-button>
        <el-button @click="reset">重置</el-button>
        <el-button type="success" @click="undialogFormVisibled">添加歌曲</el-button>
      </el-row>

      <!-- 分页器: 目前数据量较小且后端没有返回分页信息，暂未启用 -->
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="total"
        :page-count="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination> -->

      <!-- 向表格组件传递表格数据、加载状态、面板基础url -->
      <songlistTable
        :tableData="tableData"
        :loading="loading"
        :panelBaseUrl="panelBaseUrl"
        :playlistBaseUrl="playlistBaseUrl"
      ></songlistTable>
    </el-main>

    <!-- 添加歌曲弹窗 -->
    <el-dialog
      title="添加"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      @keyup.enter.native="dialogFormVisibled()"
    >
      <el-form :model="form">
        <el-form-item label="歌曲id" label-width="120px">
          <el-input
            type="text"
            :rows="1"
            @keyup.enter.native="addsongs"
            placeholder="请输入歌曲id"
            v-model="form.input5"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            dialogFormVisibled();
            addsongs();"
          >确 定</el-button
        >
      </div>
    </el-dialog>

  </el-container>
</template>

<script>
import songlistTable from "@/components/PanelComponents/songlistTable";

export default {
  components: { songlistTable },
  data() {
    return {
      panelBaseUrl: "/api/wyy",
      playlistBaseUrl: 'https://music.163.com/#/playlist?id=',
      panelTitle: "歌曲监控面板",
      loading: false,

      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      total: 1,
      totalPage: 1,
      pageIndex: 1,
      pageSize: 50,
      tableData: [],
      dialogFormVisible: false,
      form: {
        input5: "",
      },

      innerVisible: false,
      mid: 0,

      dialogName: "歌曲详情",
    };
  },
  // beforeRouteEnter: (to, from, next) => {
  //   this.reload()
  //   next()
  // },
  mounted() {
    // 通过路由获取请求路径信息和页面标题
    this.reload()
  },
  watch: {
    '$route' (to, from) { // 监听路由是否变化,如果变化就重载组件
      this.reload()
    }
  },
  methods: {
    // 重载组件
    reload() {
      this.panelBaseUrl = this.$route.meta.api;
      this.playlistBaseUrl = this.$route.meta.playlistBaseUrl
      this.panelTitle = this.$route.meta.title;
      this.getData();
    },
    // 请求表格数据
    getData() {
      var _this = this;
      _this.loading = true;

      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/musics",
        data: {
          action: "list_musics",
          m_id: _this.input1,
          m_name: _this.input2,
          s_name: _this.input3,
          s_id: _this.input4,
        },
      })
        .then(function (response) {
          _this.tableData = response.data.data;
          // console.log(response.data)
          _this.total = response.data.total;
          _this.totalPage = response.data.total_pages;
          _this.dataNotify()
          setTimeout(() => {_this.loading = false;}, 800)
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 刷新
    freshen() {
      this.getData();
      // window.location.reload();
    },
    // 获取数据成功后弹窗
    dataNotify() {
      // this.$notify({
      //   title: '成功',
      //   message: '数据更新成功',
      //   type: 'success',
      //   duration: 500,
      // });
      this.$message({
        message: '数据更新成功',
        type: 'success',
        duration: 1300,
      });
    },

    // 重置
    reset() {
      (this.input1 = ""),
        (this.input2 = ""),
        (this.input3 = ""),
        (this.input4 = "");
    },

    handleSizeChange: function (size) {
      this.pageIndex = 1;
      this.pageSize = size;
      this.getData();
      // console.log(this.pagesize) //每页下拉显示数据
    },
    handleCurrentChange: function (currentPage) {
      this.pageIndex = currentPage;
      this.getData();
      // console.log(this.currentPage) //点击第几页
    },
    // 添加歌曲弹窗开关
    undialogFormVisibled() {
      this.dialogFormVisible = true;
    },
    dialogFormVisibled() {
      this.dialogFormVisible = false;
    },
    // 添加歌曲
    addsongs() {
      let _this = this
      this.song = this.form.input5;
      // console.log(this.song)
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/musics", // 路径
        data: { action: "add_musics", m_id: this.song },
      })
        .then((res) => {
          _this.dialogFormVisible = false
          _this.getData();
          // _this.tableData=res.data.data
          // console.log(res.data.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scope>
.header {
  background-color: #545c64;
  color: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-main {

  .el-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
</style>