<template>
  <div class="table">
    <div class="e-table">
      <el-table
        @row-click="getRow"
        :data="tableData"
        ref="tableRef"
        v-loading="loading"
        border
        height="100%"
        width="100%"
        fit
      >
        <el-table-column fixed type="index" label="序号" width="50">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="m_id"
          label="歌曲id"
          width="110"
          style="z-index: 1"
        >
        </el-table-column>
        <el-table-column prop="m_name" label="歌曲名称" min-width="200">
          <template scope="scope">
            <a
              :href="['https://music.163.com/#/song?id=' + scope.row.m_id]"
              target="_Blank"
              >{{ scope.row.m_name }}</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="s_name" label="歌手名称" min-width="200">
        </el-table-column>
        <el-table-column prop="m_time" label="更新时间" min-width="100">
        </el-table-column>
        <el-table-column prop="m_7" label="7日平均播放" min-width="100">
        </el-table-column>
        <el-table-column prop="m_30" label="30日平均播放" min-width="100">
        </el-table-column>
        <el-table-column prop="m_yesterday" label="日播总量" min-width="100">
        </el-table-column>
        <el-table-column label="操作" width="170">
          <template slot-scope="scope">
            <el-button
              size="mini" type="success" plain
              @click="handleUpload(scope.row.m_id); dialogTableVisibled();"
            >
              详情
            </el-button>
            <el-button size="mini" type="danger" @click="delect" plain
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 歌曲日志弹窗 -->
    <el-dialog
      title="歌曲日志"
      :visible.sync="dialogTableVisible"
      width="710px"
    >
      <el-descriptions style="margin: -15px 0 10px">
          <el-descriptions-item label="歌曲id">{{mid}}</el-descriptions-item>
          <el-descriptions-item label="曲名">{{mname}}</el-descriptions-item>
          <el-descriptions-item label="歌手">{{sname}}</el-descriptions-item>
      </el-descriptions>

      <div class="diary-head">
        <!-- 分页器 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="d_pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="d_pageSize"
          :total="d_total"
          :page-count="d_totalPage"
          layout="total, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
      <el-table
        @row-click="getDiaryRow"
        :data="tableData1"
        v-loading="loading"
        border
        style="width: 100%; height: 60vh; overflow-y: scroll"
      >
        <el-table-column fixed type="index" label="序号" width="50">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>

        <el-table-column prop="m_date" label="日期" width="100">
        </el-table-column>
        <el-table-column prop="np_num" label="新增歌单量" width="100">
        </el-table-column>
        <!-- <el-table-column prop="new_playlist" label="新增歌单id" width="100" style="overflow: scoll; height: 55px">
        </el-table-column> -->
        <el-table-column prop="rp_num" label="减少歌单量" width="100">
        </el-table-column>
        <!-- <el-table-column prop="remove_playlist" label="减少歌单id" width="100" style="overflow: scoll; height: 55px">
        </el-table-column> -->
        <el-table-column prop="p_num" label="当前歌单量" width="100">
        </el-table-column>
        <el-table-column prop="p_num" label="变化值" width="100">
          <template scope="scope">
            {{(
              val = scope.row.np_num - scope.row.rp_num,
              val > 0 ? "+" + (val) : val
            )}}
          </template>
        </el-table-column>

        <!-- 原来的分析面板 -->
        <!-- <el-table-column
          prop="p_name"
          label="歌单"
          width="200"
          style="z-index: 1"
        >
          <template scope="scope">
            <a
              :href="['https://music.163.com/#/playlist?id=' + scope.row.p_id]"
              target="_Blank"
              >{{ scope.row.p_name }}</a
            >
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="p_username" label="歌单主" width="100">
        </el-table-column>
        <el-table-column prop="p_plays" label="总播放" width="110">
        </el-table-column>
        <el-table-column prop="p_stars" label="总收藏" width="100">
        </el-table-column>
        <el-table-column prop="p_shares" label="总分享" width="100">
        </el-table-column>
        <el-table-column prop="p_comments" label="总评论" width="100">
        </el-table-column>
        <el-table-column prop="p_7" label="7日播放" width="100">
        </el-table-column>
        <el-table-column prop="p_30" label="30日播放" width="100">
        </el-table-column>
        <el-table-column prop="p_yesterday" label="日播" width="100">
        </el-table-column>
        <el-table-column prop="p_tremarks" label="达备" width="180">
        </el-table-column>
        <el-table-column prop="remarks" label="歌备" width="180">
        </el-table-column> -->

        <el-table-column label="操作" width="100">
          <template>
            <!-- 切换抽屉和弹窗两种歌单详情的显示方式 -->
            <el-button size="mini" type="success" @click="drawer = true" plain> 详情 </el-button>
            <!-- <el-button size="mini" type="primary" @click="innerVisible = true"> 详情 </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 歌单详情弹窗 -->
    <!-- <el-dialog
      :title="dialogName2"
      width="1480px"
      height="80%"
      :visible.sync="innerVisible"
      :append-to-body="true"
      @opened="get_decs"
    >
      <div class="pag-leg">
        <el-pagination
          @size-change="t_handleSizeChange"
          @current-change="t_handleCurrentChange"
          :current-page.sync="t_pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="t_pageSize"
          layout="total, prev, pager, next, jumper"
          :total="t_total"
        >
        </el-pagination>
        <div class="legend">
          <div class="block" style="background-color: rgb(236, 255, 236)"></div>
          <p>新增歌单</p>
          <div class="block" style="background-color: rgb(255, 235, 235)"></div>
          <p>移除歌单</p>
        </div>
      </div>

      <el-table
        :data="tableDecsShowData"
        :row-class-name="tableRowClassName"
        height="800"
        border
        style="width: 100%"
        v-loading="decsLoading"
      >
        <el-table-column fixed type="index" label="序号" width="50">
          <template slot-scope="scope">
            <span v-text="getIndex(scope.$index)"> </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="p_name"
          label="歌单"
          width="200"
          style="z-index: 1"
        >
          <template scope="scope">
            <a
              :href="[playlistBaseUrl + scope.row.p_id]"
              target="_Blank"
              >{{ scope.row.p_name }}</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="p_username" label="歌单主" width="100">
        </el-table-column>
        <el-table-column prop="p_plays" label="总播放" width="110">
        </el-table-column>
        <el-table-column prop="p_stars" label="总收藏" width="100">
        </el-table-column>
        <el-table-column prop="p_shares" label="总分享" width="100">
        </el-table-column>
        <el-table-column prop="p_comments" label="总评论" width="100">
        </el-table-column>
        <el-table-column prop="p_7" label="7日播放" width="100">
        </el-table-column>
        <el-table-column prop="p_30" label="30日播放" width="100">
        </el-table-column>
        <el-table-column prop="p_yesterday" label="日播" width="100">
        </el-table-column>
        <el-table-column prop="p_tremarks" label="达备" width="180">
        </el-table-column
        ><el-table-column prop="remarks" label="歌备" width="180">
        </el-table-column>
      </el-table>
    </el-dialog> -->

    <!-- 歌单详情抽屉面板 -->
    <el-drawer
      :title="dialogName2"
      direction="btt"
      size="90%"
      @opened="get_decs"
      :visible.sync="drawer"
    >
      <div class="drawerTab">
        <!-- 这里放图例和翻页器 -->
        <div class="pag-leg">
          <el-pagination
            @size-change="t_handleSizeChange"
            @current-change="t_handleCurrentChange"
            :current-page.sync="t_pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="t_pageSize"
            layout="total, prev, pager, next, jumper"
            :total="t_total"
          >
          </el-pagination>
          <div class="legend">
            <div class="block" style="background-color: rgb(255, 255, 255)"></div>
            <p>未变化歌单</p>
            <div class="block" style="background-color: rgb(236, 255, 236)"></div>
            <p>新增歌单</p>
            <div class="block" style="background-color: rgb(255, 235, 235)"></div>
            <p>移除歌单</p>
          </div>
        </div>

        <!-- 日志歌单详细表格 -->
        <el-table
          :data="tableDecsShowData"
          :row-class-name="tableRowClassName"
          @row-click="getDecsRow"
          height="800"
          border fit
          style="width: 100%"
          v-loading="decsLoading"
          @sort-change="sortChange"
        >
          <el-table-column fixed type="index" label="序号" width="50">
            <template slot-scope="scope">
              <span v-text="getIndex(scope.$index)"> </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="p_name"
            label="歌单"
            style="z-index: 1"
            min-width="150"
          >
            <template scope="scope">
              <a
                :href="[playlistBaseUrl + scope.row.p_id]"
                target="_Blank"
                >{{ scope.row.p_name }}</a
              >
            </template>
          </el-table-column>
          <!-- <el-table-column prop="site" label="位置" min-width="80" sortable="custom">
          </el-table-column> -->
          <el-table-column prop="p_username" label="歌单主">
          </el-table-column>
          <el-table-column prop="p_plays" label="总播放" width="100" sortable="custom">
          </el-table-column>
          <el-table-column prop="p_stars" label="总收藏" width="100" sortable="custom">
          </el-table-column>
          <el-table-column prop="p_shares" label="总分享" width="100" sortable="custom">
          </el-table-column>
          <el-table-column prop="p_comments" label="总评论" width="100" sortable="custom">
          </el-table-column>
          <el-table-column prop="p_7" label="7日播放" width="100" sortable="custom">
          </el-table-column>
          <el-table-column prop="p_30" label="30日播放" width="120" sortable="custom">
          </el-table-column>
          <el-table-column prop="p_yesterday" label="日播" width="100" sortable="custom">
          </el-table-column>
          <el-table-column prop="p_stars_yesterday" label="日增收藏" min-width="120" sortable="custom">
          <template scope="scope">
            {{(
              scope.row.p_stars_yesterday || '0'
            )}}
          </template>
          </el-table-column>
          <el-table-column prop="p_tremarks" label="达备">
          </el-table-column
          ><el-table-column prop="remarks" label="歌备">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="85">
            <template>
              <el-button size="mini" type="success" style="margin: 0 1px" @click="anaDialogTableVisibled" plain
                >分析</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-drawer>

    <el-dialog
      title="分析"
      :visible.sync="anaDialogTableVisible"
      :append-to-body="true"
      @opened="loadLine"
    >
      <!-- @open="openFun()" -->
      <div
        id="myChart"
        class="chart"
        ref="chart1"
        style="width: 100%; height: 376px"
        v-if="dialogTableVisible"
      ></div>
    </el-dialog>
  </div>
</template>

<script>
import echarts from "echarts";

export default {
  watch: {
    // tableData是el-table绑定的数据
    tableData: {
      // 解决表格显示错位问题
      handler () {
        this.$nextTick(() => {
          // tableRef是el-table绑定的ref属性值
          this.$refs.tableRef.doLayout() // 对 Table 进行重新布局
        })
      },
      deep: true
    }
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    panelBaseUrl: {
      type: String,
      default: "/api/wyy",
    },
    playlistBaseUrl: {
      type: String,
      default: 'https://music.163.com/#/playlist?id='
    },
  },
  data() {
    return {
      // 日志分页变量
      d_total: 1,
      d_totalPage: 1,
      d_pageIndex: 1,
      d_pageSize: 50,

      // 歌单详情分页变量
      t_total: 1,
      t_totalPage: 1,
      t_pageIndex: 1,
      t_pageSize: 50,

      pageIndex: 1,
      pageSize: 50,
      // tableData: [],
      tableData1: [], // 展示日志表格数据
      tableDecsData: [], // 日志歌单详情数据
      tableDecsRaw: [], // 日志歌单详情原始数据
      tableDecsShowData: [], // 用于展示歌单详情数据
      decsLoading: false,   // 详情数据加载状态指示

      anaDialogTableVisible: false, // 分析面板开关
      dialogTableVisible: false,
      dialogFormVisible: false,
      innerVisible: false,

      drawer: false,
      chart: "",
      labList: [],

      playList: [],
      starList: [],
      shareList: [],
      commentList: [],
      timeList: [],
      chart: "",
      mid: 0,
      m_date: "",

      // dialogName: "歌曲日志",
      dialogName2: "歌曲详情",  // 日志歌单详情弹窗显示的标题

      mid: '',
      mname: '',
      sname: '',

      pid: '',
    };
  },
  methods: {
    // 点击行时触发获取行内容
    getRow(row) {
      // console.log(row.p_id, row.m_id);
      window.ids = row.p_id;
      this.mid = row.m_id;
      this.mname = row.m_name;
      this.sname = row.s_name;
      // this.dialogName = `歌曲: ${this.mname}  歌手: ${this.sname}`;
    },
    getDiaryRow(row) {
      this.m_date = row.m_date;
      this.dialogName2 = `> 监控日期: ${this.m_date}  >  歌曲: ${this.mname}`;
    },
    getIndex($index) {
      return $index + 1 + this.pageSize * (this.pageIndex - 1);
    },
    drawerVisibled() {
      this.drawer = true;
    },
    // 分析面板开关
    anaDialogTableVisibled() {
      this.anaDialogTableVisible = true;
    },
    dialogTableVisibled() {
      this.dialogTableVisible = true;
    },
    dialogFormVisibled() {
      this.dialogFormVisible = false;
    },
    // 日志弹窗触发
    handleUpload(m_id) {
      let _this = this;
      m_id = m_id || _this.mid;
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/musics", // 路径
        data: {
          action: "diary_music",
          m_id: m_id,
          page: _this.d_pageIndex,
          limit: _this.d_pageSize,
        }, // 测试：页码待修改
      })
        .then((res) => {
          _this.tableData1 = res.data.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delect() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let _this = this;
          this.$http({
            method: "post",
            url: _this.panelBaseUrl + "/musics", // 路径
            data: { action: "delete_musics", m_id: this.mid },
          })
            .then((res) => {
              this.getData();
              // console.log(res.data.data)
            })
            .catch((err) => {
              console.log(err);
            });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 歌单详情表格行样式获取
    tableRowClassName({ row, rowIndex }) {
      if (row.type === "old") {
        return "";
      } else if (row.type === "new") {
        return "new-row";
      } else if (row.type === "removed") {
        return "removed-row";
      } else {
        return "";
      }
    },
    // 获取指定日期详细歌单
    get_decs() {
      let _this = this;
      this.decsLoading = true;
      _this.tableDecsData = []
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/musics", // 路径
        data: { action: "desc_musics", m_id: _this.mid, date: _this.m_date },
      })
        .then((res) => {
          let d_playlist = [];
          let data = res.data.data;
          _this.d_totalPage = res.data.total_pages;

          data.playlist.forEach((val, idx, arr) => {
            // 遍历当前歌单列表，为新增歌单设置标签
            let np = JSON.parse(JSON.stringify(val)); // 拷贝原歌单对象
            if (
              data.new_playlist.find((nval) => {
                return nval.p_id === val.p_id;
              })
            ) {
              np.type = "new";
            } else {
              np.type = "old";
            }
            d_playlist.push(np);
          });

          // console.log('check1')
          data.remove_playlist.forEach((val, idx, arr) => {
            // 遍历当前歌单列表，为移除歌单设置标签
            let np = JSON.parse(JSON.stringify(val)); // 拷贝原歌单对象
            np.type = "removed";
            d_playlist.push(np);
          });
          // console.log('check2')
          _this.tableDecsRaw = JSON.parse(JSON.stringify(d_playlist)) // 拷贝一份原始数据作排序备份
          _this.tableDecsData = d_playlist;
          this.playlist_detail_upload();
          // _this.decsLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 日志页分页器触发
    handleSizeChange: function (size) {
      this.d_pageIndex = 1;
      this.d_pageSize = size;
      this.handleUpload(this.mid);
    },
    handleCurrentChange: function (currentPage) {
      this.d_pageIndex = currentPage;
      this.handleUpload(this.mid);
    },
    // 日志页歌单详情分页器触发
    t_handleSizeChange: function (size) {
      this.t_pageIndex = 1;
      this.t_pageSize = size;
      this.playlist_detail_upload(this.mid);
    },
    t_handleCurrentChange: function (currentPage) {
      this.t_pageIndex = currentPage;
      this.playlist_detail_upload(this.mid);
    },
    // 载入翻页更新内容
    playlist_detail_upload() {
      let _this = this;
      _this.decsLoading = true;

      _this.t_total = _this.tableDecsData.length;
      _this.t_totalPage = parseInt(
        _this.tableDecsData.length / _this.t_pageSize + 0.5
      );
      let start_num = (_this.t_pageIndex - 1) * _this.t_pageSize;
      let end_num = _this.t_pageIndex * _this.t_pageSize;
      _this.tableDecsShowData = _this.tableDecsData.slice(start_num, end_num);
      // console.log(start_num, end_num, _this.t_totalPage, _this.tableDecsShowData)

      _this.decsLoading = false;
    },

    // 排序
    sortChange(arg) {
      // console.log(arg)
      // {column: {…}, prop: 'p_stars', order: 'descending'}
      this.decsLoading = true
      // this.t_pageIndex = 1
      if(arg.order) {
        this.tableDecsData.sort((a, b) => {
          return arg.order === 'descending'? b[arg.prop] - a[arg.prop] : a[arg.prop] - b[arg.prop]
        })
      } else {
        this.tableDecsData = JSON.parse(JSON.stringify(this.tableDecsRaw))
      }
      
      this.playlist_detail_upload()
    },

    // 获取日志详情行
    getDecsRow(row) {
      this.pid = row.p_id
      // console.log(this.pid)
    },

    // 分析图
    loadLine() {
      let _this = this
      this.chart = echarts.init(document.querySelector("#myChart"));
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/parse", // 路径
        data: { p_id: _this.pid, date: [] },
      })
        .then((res) => {
          this.labList = res.data.data;
          (this.playList = []),
            (this.commentList = []),
            (this.shareList = []),
            (this.starList = []),
            (this.timeList = []);
          this.labList.forEach((item) => {
            this.playList.push(item.l_plays);
            this.starList.push(item.l_stars);
            this.commentList.push(item.l_comments);
            this.shareList.push(item.l_shares);
            this.timeList.push(item.l_time);
          });
          let option = {
            title: {},
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: ["播放量", "收藏量", "分享量", "评论量"],
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: this.timeList, // x轴数据
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "播放量",
                type: "line",
                data: this.playList, // y轴数据2
              },

              {
                name: "收藏量",
                type: "line",
                data: this.starList, // y轴数据3
              },
              {
                name: "分享量",
                type: "line",
                data: this.shareList, // y轴数据5
              },
              {
                name: "评论量",
                type: "line",
                data: this.commentList, // y轴数据5
              },
            ],
          };
          this.chart.setOption(option);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scope>
.table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .e-table {
    height: 80vh;
    width: 100%;
    overflow: hidden;
  }
}

.el-dialog__body, .el-drawer {
  .diary-head {
    .el-pagination {
      margin-bottom: 15px;
    }
  }

  /* 抽屉面板外层样式 */
  .drawerTab {
    padding: 0 20px 10px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  .pag-leg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    align-items: center;
    margin-bottom: 20px;

    .legend {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 30%;

      .block {
        width: 50px;
        height: 22px;
        margin-right: 10px;
        border: solid 1px #666;
      }

      p {
        margin-right: 15px;
      }
    }
  }
}

/* 日志歌单详情行状态样式 */
.el-table .new-row {
  background: rgb(236, 255, 236);
}

.el-table .removed-row {
  background: rgb(255, 235, 235);
}
</style>