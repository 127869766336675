<template>
  <div class="table">
    <el-table
      @row-click="getRow"
      :data="tableData"
      ref="tableRef"
      v-loading="loading"
      border fit
      style="width: 100%"
      height="100%"
    >
      <el-table-column fixed type="index" label="序号" width="50">
        <template slot-scope="scope">
          <span v-text="getIndex(scope.$index)"> </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="p_name"
        label="歌单"
        style="z-index: 1"
        min-width="200"
      >
        <template scope="scope">
          <a
            :href="[playlistBaseUrl + scope.row.p_id + ($route.meta.api === '/api/kugou'? '.html':'')]"
            target="_Blank"
            >{{ scope.row.p_name }}</a
          >
        </template>
      </el-table-column>
      <el-table-column prop="p_username" label="歌单主" width="100" min-width="150">
      </el-table-column>
      <el-table-column prop="p_plays" v-if="this.$route.meta.api !== '/api/qishui'" label="总播放" min-width="80">
      </el-table-column>
      <el-table-column v-if="this.$route.meta.api === '/api/qishui'" prop="p_stars" label="总收藏" min-width="80">
      </el-table-column>
      <el-table-column v-if="this.$route.meta.api === '/api/qishui'" prop="p_shares" label="总分享" min-width="80">
      </el-table-column>
      <!-- <el-table-column v-if="this.$route.meta.api === '/api/qishui'" prop="p_comments" label="总评论" min-width="80">
      </el-table-column> -->
      <el-table-column prop="p_7" v-if="this.$route.meta.api !== '/api/qishui'" label="7日播放" min-width="80">
      </el-table-column>
      <el-table-column prop="p_30" v-if="this.$route.meta.api !== '/api/qishui'" label="30日播放" min-width="80">
      </el-table-column>
      <el-table-column prop="p_yesterday" v-if="this.$route.meta.api !== '/api/qishui'" label="日播" min-width="80">
      </el-table-column>
      <el-table-column prop="p_stars_7" label="7日收藏" min-width="80">
      </el-table-column>
      <el-table-column prop="p_stars_30" label="30日收藏" min-width="80">
      </el-table-column>
      <el-table-column prop="p_stars_yesterday" label="日增收藏" min-width="80">
        <template scope="scope">
          {{(
            scope.row.p_stars_yesterday || ''
          )}}
        </template>
      </el-table-column>
      <el-table-column prop="p_tprice_level" label="性价比" min-width="80">
        <template scope="scope">
          <el-tag
            :class="('level' + scope.row.p_tprice_level)"
          >{{getPriceLevel(scope.row.p_tprice_level)}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="assign_wx_name" label="指派微信" min-width="110">
        <template scope="scope">
          <el-tag
            :class="tableData[scope.$index].assign_wx_name? 'wx' : 'unknown'"
          >{{scope.row.assign_wx_name || "无"}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="this.$route.meta.api === '/api/qq'" prop="puserid" label="QQ号" min-width="120">
      </el-table-column>
      <el-table-column prop="p_tremarks" label="达备" min-width="180">
      </el-table-column>
      <el-table-column prop="remarks" label="歌备" min-width="180">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="220">
        <template>
          <el-button size="mini" type="success" style="margin: 0 1px" @click="dialogTableVisibled" plain
            >分析</el-button
          >
          <el-button size="mini" type="primary" style="margin: 0 1px" @click="undialogFormVisibled" plain
            >达备</el-button
          >
          <el-button size="mini" type="primary" style="margin: 0 1px" @click="gddialogFormVisibled" plain
            >歌备</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="分析"
      :visible.sync="dialogTableVisible"
      :append-to-body="true"
      @opened="loadLine"
    >
      <!-- @open="openFun()" -->
      <div
        id="myChart"
        class="chart"
        ref="chart1"
        style="width: 100%; height: 376px"
        v-if="dialogTableVisible"
      ></div>
    </el-dialog>

    <el-dialog
      title="达人备注"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
    >
      <el-form>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="添加备注"
            v-model="remarks"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            dialogFormVisibled();
            clearremark()
          ;">
          清空备注
        </el-button>
        <el-button
          type="primary"
          @click="
            dialogFormVisibled();
            handleUpload();"
        >确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="歌单备注"
      :visible.sync="dialogFormVisible1"
      :append-to-body="true"
    >
      <el-form>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="添加备注"
            v-model="gremarks"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            dialogFormVisibled();
            clearremark1();"
          >清空备注</el-button
        >
        <el-button
          type="primary"
          @click="
            dialogFormVisibled();
            handleUploadgd();"
          >确 定</el-button
        >
      </div>
    </el-dialog>

  </div>
</template>

<script>
import echarts from "echarts";

export default {
  watch: {
    // tableData是el-table绑定的数据
    tableData: {
      // 解决表格显示错位问题
      handler () {
        this.$nextTick(() => {
          // tableRef是el-table绑定的ref属性值
          this.$refs.tableRef.doLayout() // 对 Table 进行重新布局
        })
      },
      deep: true
    }
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    panelBaseUrl: {
      type: String,
      default: '/api/wyy'
    },
    playlistBaseUrl: {
      type: String,
      default: 'https://music.163.com/#/playlist?id='
    },
    value2: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      playList: [],
      starList: [],
      shareList: [],
      commentList: [],
      timeList: [],
      chart: "",
      total: 1,
      totalPage: 1,
      formData: "",
      pageIndex: 1,
      pageSize: 50,
      labList: [],

      options: [],
      value: "",

      dialogTableVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible1: false,
      dialogFormVisible: false,

      formLabelWidth: "120px",
      remarks: "",
      gremarks: "",
    };
  },
  methods: {
    // 分析面板开关
    dialogTableVisibled() {
      this.dialogTableVisible = true;
    },
    // 达备面板开关
    undialogFormVisibled() {
      this.dialogFormVisible = true;
    },
    // 歌备面板开关
    gddialogFormVisibled() {
      this.dialogFormVisible1 = true;
    },
    // 关闭面板
    dialogFormVisibled() {
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    getIndex($index) {
      return $index + 1 + this.pageSize * (this.pageIndex - 1);
    },

    // 获取id
    getRow(row) {
      window.ids = row.p_id;
      this.remarks = row.p_tremarks;
      this.gremarks = row.remarks;
    },

    // 修改达备
    handleUpload() {
      let _this = this
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/playlists", // 路径
        data: {
          action: "modify_playlist",
          pid: window.ids,
          newdata: { t_remarks: _this.remarks },
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((res) => {
          // _this.getData();
          _this.$emit('reloadData');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 修改歌备
    handleUploadgd() {
      let _this = this
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/playlists", // 路径
        data: {
          action: "modify_playlist",
          pid: window.ids,
          newdata: { p_remarks: _this.gremarks },
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          // this.getData();
          _this.$emit('reloadData');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clearremark() {
      let _this = this
      this.remarks = "";
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/playlists", // 路径
        data: {
          action: "modify_playlist",
          pid: window.ids,
          newdata: { t_remarks: _this.remarks },
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((res) => {
          // this.getData();
          _this.$emit('reloadData');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearremark1() {
      let _this = this
      this.gremarks = "";
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/playlists", // 路径
        data: {
          action: "modify_playlist",
          pid: window.ids,
          newdata: { p_remarks: _this.gremarks },
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          // this.getData();
          _this.$emit('reloadData');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取价格标签
    getPriceLevel(tag) {
      if(tag != '') tag = Number(tag)
      switch (tag) {
        case 0: return '无'
        case 1: return '非常贵'
        case 2: return '贵'
        case 3: return '中等'
        case 4: return '便宜'
        default: return '无'
      }
    },

    // 分析图
    loadLine() {
      let _this = this
      this.chart = echarts.init(document.querySelector("#myChart"));
      this.$http({
        method: "post",
        url: _this.panelBaseUrl + "/parse", // 路径
        data: { p_id: window.ids, date: this.value2 },
      })
        .then((res) => {
          this.labList = res.data.data;
          (this.playList = []),
            (this.commentList = []),
            (this.shareList = []),
            (this.starList = []),
            (this.timeList = []);
          this.labList.forEach((item) => {
            this.playList.push(item.l_plays);
            this.starList.push(item.l_stars);
            this.commentList.push(item.l_comments);
            this.shareList.push(item.l_shares);
            this.timeList.push(item.l_time);
          });
          let option = {
            title: {},
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: ["播放量", "收藏量", "分享量", "评论量"],
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: this.timeList, // x轴数据
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "播放量",
                type: "line",
                data: this.playList, // y轴数据2
              },

              {
                name: "收藏量",
                type: "line",
                data: this.starList, // y轴数据3
              },
              {
                name: "分享量",
                type: "line",
                data: this.shareList, // y轴数据5
              },
              {
                name: "评论量",
                type: "line",
                data: this.commentList, // y轴数据5
              },
            ],
          };
          this.chart.setOption(option);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scope>
.table {
  flex: 1;

  /* 标签颜色 */
  .level4, .ws1, .wx {
    background-color: #f0f9eb;
    border-color: #e1f3d8;
    color: #67c23a;
  }

  .level1, .ws3 {
    background-color: #fef0f0;
    border-color: #fde2e2;
    color: #f56c6c;
  }

  .level2, .ws2 {
    background-color: #fdf6ec;
    border-color: #faecd8;
    color: #e6a23c;
  }

  .level3 {
    background-color: #ecf5ff;
    border-color: #d9ecff;
    color: #409EFF;
  }

  .level0, .level, .ws0, .unknown {
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #909399;
  }
}



</style>