<template>
    <div >
        <!-- <div class="logo">
            <a href="#">音乐系统</a>
        </div>
        <div class="taskbar"  @click="toggleClick">
            <i class="el-icon-s-fold" v-show="isShow"></i>
            <i class="el-icon-s-unfold" v-show="!isShow"></i>
        </div> -->
        <el-header> 
            <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab" @tab-click="linkTo">
              <el-tab-pane
                name="1"
                label="网易云音乐"
              >
              </el-tab-pane>
              <el-tab-pane
                name="2"
                label="网易云歌曲监控"
              >
              </el-tab-pane>
              <el-tab-pane
                name="3"
                label="QQ音乐"
              >
              </el-tab-pane>
            </el-tabs>
          </el-header> 
    </div>
</template>
<script>
export default {
    name:'topList',
    data() {
        return {
            editableTabsValue: '2',
        editableTabs: [{
          title: '网易云音乐',
          name: '1',
          content: 'Tab 1 content'
        }, 
        {
          title: '网易云歌曲监控',
          name: '3',
          content: 'Tab 3 content'
        },
        {
          title: 'QQ音乐',
          name: '2',
          content: 'Tab 2 content'
        }
      ],
        
        }
    },
    methods: {
      linkTo(tab,event){
        // this.$router.replace("/home");
        // console.log(tab,event)
        if(tab.name == '1'){
          this.first()
        }else if(tab.name == '2'){
          this.second()
        }else{
          this.third()
        }
      },
      first(){
        this.$router.replace("/home");
      },
      second(){
        this.$router.replace("/control");
      },
      third(){
        this.$router.replace("/qqmusic");
      },

        addTab(targetName) {
        let newTabName = ++this.tabIndex + '';
        this.editableTabs.push({
          title: 'New Tab',
          name: newTabName,
          content: 'New Tab content'
        });
        this.editableTabsValue = newTabName;
      },
      removeTab(targetName) {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter(tab => tab.name !== targetName);
      }
    },
}
</script>
<style lang="less" scoped>
  /* .element.style {
    height: 40px;
} */
.el-header[data-v-613e5ae0] {
    background-color: #ffffff;
    color: #ffffff;
    line-height:40px;
    height: 40px;
}
 
/* .center{
    width: 100%;
    height: 45px;
    background-color: black;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.center .logo a{
    width: 200px;
    line-height: 45px;
    font-size: 18px;
    color: white;
    padding-left: 20px;
    float: left;
}
.taskbar{
    float: left;
    height: 32px;
    width: 32px;
    margin-top: 7px;
}
.taskbar i{
    display: block;
    font-size: 25px;
    text-align: center;
    margin-top: 3px;
    color: gray;
}
.taskbar i:hover{
    color: white;
} */
</style>